@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&family=Heebo:wght@400;700&display=swap');

@import "npm:microtip/microtip.css";
@import "npm:bootstrap-icons/font/bootstrap-icons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    .button-main {

        @apply bg-blue-700;
        @apply border-blue-300;
        @apply rounded-lg;
        @apply text-white;
        @apply font-bold;
        @apply p-3;
        @apply border;
        @apply transition-all;


    }

    .button-main:hover {

        @apply bg-blue-600;

    }

    .button-main:disabled {
        @apply bg-blue-800;
    }

    .content-div {
        @apply bg-gray-700;
        @apply rounded-xl;
        @apply shadow-xl;
    }

    .font-heebo {
        font-family: 'Heebo', sans-serif;
    }

    .font-fira {
        font-family: 'Fira Mono', monospace;
    }

    .table-code {
        @apply bg-gray-900;
        @apply text-gray-400; 
        @apply font-fira;
        @apply p-1;
        @apply rounded-md;
    }

    .sub-heading {
        @apply text-xl;
        @apply text-blue-500;
        @apply font-bold;
        @apply mb-3;
    }

    .md-grid-rows {
        grid-template-rows: auto 18em;
    }

    .bulb {
        @apply w-6;
        @apply h-6;
        @apply rounded-full;

        @apply bg-gray-900;
        @apply border-gray-600;
        @apply border;

    }

    .bulb[on=true] {
        @apply bg-yellow-200;
        @apply border-yellow-50;
    }

    .switch {
        @apply w-6;
        @apply h-6;
        @apply rounded-full;

        @apply bg-red-700;
        @apply border-red-400;
        @apply border;

        @apply hover:cursor-pointer;

    }

    .switch[on=true] {
        @apply bg-green-700;
        @apply border-green-400;
    }

    a.in-para {
        @apply text-blue-500;
        @apply transition-all;
    }

    a:hover {
        @apply text-blue-300;
    }

    #render-canvas {
        @apply transition-shadow
    }

    #render-canvas[running=false] {
        @apply border;
        @apply border-gray-500;
    }

    #render-canvas[running=true] {
        @apply border;
        @apply border-blue-500;
    }

    .good-error {

        @apply text-lg;
        @apply text-green-400;
        @apply font-bold;

    }

    .bad-error {

        @apply text-lg;
        @apply text-red-600;
        @apply font-bold;

    }

    .datalist-default {
        @apply bg-gray-800;
        @apply border-b;
        @apply border-gray-400;
        @apply focus:outline-none;

    }

    .datalist-default[valid-reg=true] {
        @apply text-white;
    }

    .datalist-default[valid-reg=false] {
        @apply text-red-600;
    }

    .example-container {
        @apply mt-1;
        @apply p-2;
        @apply border-gray-400;
        @apply border-b;
    }

    .example-container h3 {
        @apply text-sm;
        @apply font-bold;
    }

    .code-name {
        @apply w-fit;
        @apply border-r;
        @apply pr-2;
        @apply pl-2;
        @apply h-full;
        @apply border-gray-500;
        @apply text-center;
        @apply inline-block;
    }

    .code-name[data-selected=true] {
        @apply font-bold;
    }

    .code-name > * {
        @apply inline-block;
    }

    .program-definition {

        @apply p-2;
        font-family: 'Fira Mono', monospace;

    }

}