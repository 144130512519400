@import "https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&family=Heebo:wght@400;700&display=swap";
[aria-label][role~="tooltip"] {
  position: relative;
}

[aria-label][role~="tooltip"]:before, [aria-label][role~="tooltip"]:after {
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all var(--microtip-transition-duration, .18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  box-sizing: border-box;
  z-index: 10;
  transform-origin: top;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

[aria-label][role~="tooltip"]:before {
  content: "";
  background-size: 100% !important;
}

[aria-label][role~="tooltip"]:after {
  color: #fff;
  content: attr(aria-label);
  font-size: var(--microtip-font-size, 13px);
  font-weight: var(--microtip-font-weight, normal);
  text-transform: var(--microtip-text-transform, none);
  white-space: nowrap;
  box-sizing: content-box;
  background: #111111e6;
  border-radius: 4px;
  padding: .5em 1em;
}

[aria-label][role~="tooltip"]:hover:before, [aria-label][role~="tooltip"]:hover:after, [aria-label][role~="tooltip"]:focus:before, [aria-label][role~="tooltip"]:focus:after {
  opacity: 1;
  pointer-events: auto;
}

[role~="tooltip"][data-microtip-position|="top"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 18px;
  height: 6px;
  margin-bottom: 5px;
}

[role~="tooltip"][data-microtip-position|="top"]:after {
  margin-bottom: 11px;
}

[role~="tooltip"][data-microtip-position|="top"]:before {
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

[role~="tooltip"][data-microtip-position|="top"]:hover:before {
  transform: translate3d(-50%, -5px, 0);
}

[role~="tooltip"][data-microtip-position|="top"]:after {
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

[role~="tooltip"][data-microtip-position="top"]:hover:after {
  transform: translate3d(-50%, -5px, 0);
}

[role~="tooltip"][data-microtip-position="top-left"]:after {
  bottom: 100%;
  transform: translate3d(calc(16px - 100%), 0, 0);
}

[role~="tooltip"][data-microtip-position="top-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), -5px, 0);
}

[role~="tooltip"][data-microtip-position="top-right"]:after {
  bottom: 100%;
  transform: translate3d(-16px, 0, 0);
}

[role~="tooltip"][data-microtip-position="top-right"]:hover:after {
  transform: translate3d(-16px, -5px, 0);
}

[role~="tooltip"][data-microtip-position|="bottom"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 18px;
  height: 6px;
  margin-top: 5px;
  margin-bottom: 0;
}

[role~="tooltip"][data-microtip-position|="bottom"]:after {
  margin-top: 11px;
}

[role~="tooltip"][data-microtip-position|="bottom"]:before {
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

[role~="tooltip"][data-microtip-position|="bottom"]:hover:before {
  transform: translate3d(-50%, 0, 0);
}

[role~="tooltip"][data-microtip-position|="bottom"]:after {
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

[role~="tooltip"][data-microtip-position="bottom"]:hover:after {
  transform: translate3d(-50%, 0, 0);
}

[role~="tooltip"][data-microtip-position="bottom-left"]:after {
  top: 100%;
  transform: translate3d(calc(16px - 100%), -10px, 0);
}

[role~="tooltip"][data-microtip-position="bottom-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), 0, 0);
}

[role~="tooltip"][data-microtip-position="bottom-right"]:after {
  top: 100%;
  transform: translate3d(-16px, -10px, 0);
}

[role~="tooltip"][data-microtip-position="bottom-right"]:hover:after {
  transform: translate3d(-16px, 0, 0);
}

[role~="tooltip"][data-microtip-position="left"]:before, [role~="tooltip"][data-microtip-position="left"]:after {
  inset: 50% 100% auto auto;
  transform: translate3d(10px, -50%, 0);
}

[role~="tooltip"][data-microtip-position="left"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 6px;
  height: 18px;
  margin-bottom: 0;
  margin-right: 5px;
}

[role~="tooltip"][data-microtip-position="left"]:after {
  margin-right: 11px;
}

[role~="tooltip"][data-microtip-position="left"]:hover:before, [role~="tooltip"][data-microtip-position="left"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

[role~="tooltip"][data-microtip-position="right"]:before, [role~="tooltip"][data-microtip-position="right"]:after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate3d(-10px, -50%, 0);
}

[role~="tooltip"][data-microtip-position="right"]:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  width: 6px;
  height: 18px;
  margin-bottom: 0;
  margin-left: 5px;
}

[role~="tooltip"][data-microtip-position="right"]:after {
  margin-left: 11px;
}

[role~="tooltip"][data-microtip-position="right"]:hover:before, [role~="tooltip"][data-microtip-position="right"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

[role~="tooltip"][data-microtip-size="small"]:after {
  white-space: initial;
  width: 80px;
}

[role~="tooltip"][data-microtip-size="medium"]:after {
  white-space: initial;
  width: 150px;
}

[role~="tooltip"][data-microtip-size="large"]:after {
  white-space: initial;
  width: 260px;
}

/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: bootstrap-icons;
  src: url("bootstrap-icons.3536be6d.woff2") format("woff2"), url("bootstrap-icons.5b9cac4e.woff") format("woff");
}

.bi:before, [class^="bi-"]:before, [class*=" bi-"]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.bi-123:before {
  content: "";
}

.bi-alarm-fill:before {
  content: "";
}

.bi-alarm:before {
  content: "";
}

.bi-align-bottom:before {
  content: "";
}

.bi-align-center:before {
  content: "";
}

.bi-align-end:before {
  content: "";
}

.bi-align-middle:before {
  content: "";
}

.bi-align-start:before {
  content: "";
}

.bi-align-top:before {
  content: "";
}

.bi-alt:before {
  content: "";
}

.bi-app-indicator:before {
  content: "";
}

.bi-app:before {
  content: "";
}

.bi-archive-fill:before {
  content: "";
}

.bi-archive:before {
  content: "";
}

.bi-arrow-90deg-down:before {
  content: "";
}

.bi-arrow-90deg-left:before {
  content: "";
}

.bi-arrow-90deg-right:before {
  content: "";
}

.bi-arrow-90deg-up:before {
  content: "";
}

.bi-arrow-bar-down:before {
  content: "";
}

.bi-arrow-bar-left:before {
  content: "";
}

.bi-arrow-bar-right:before {
  content: "";
}

.bi-arrow-bar-up:before {
  content: "";
}

.bi-arrow-clockwise:before {
  content: "";
}

.bi-arrow-counterclockwise:before {
  content: "";
}

.bi-arrow-down-circle-fill:before {
  content: "";
}

.bi-arrow-down-circle:before {
  content: "";
}

.bi-arrow-down-left-circle-fill:before {
  content: "";
}

.bi-arrow-down-left-circle:before {
  content: "";
}

.bi-arrow-down-left-square-fill:before {
  content: "";
}

.bi-arrow-down-left-square:before {
  content: "";
}

.bi-arrow-down-left:before {
  content: "";
}

.bi-arrow-down-right-circle-fill:before {
  content: "";
}

.bi-arrow-down-right-circle:before {
  content: "";
}

.bi-arrow-down-right-square-fill:before {
  content: "";
}

.bi-arrow-down-right-square:before {
  content: "";
}

.bi-arrow-down-right:before {
  content: "";
}

.bi-arrow-down-short:before {
  content: "";
}

.bi-arrow-down-square-fill:before {
  content: "";
}

.bi-arrow-down-square:before {
  content: "";
}

.bi-arrow-down-up:before {
  content: "";
}

.bi-arrow-down:before {
  content: "";
}

.bi-arrow-left-circle-fill:before {
  content: "";
}

.bi-arrow-left-circle:before {
  content: "";
}

.bi-arrow-left-right:before {
  content: "";
}

.bi-arrow-left-short:before {
  content: "";
}

.bi-arrow-left-square-fill:before {
  content: "";
}

.bi-arrow-left-square:before {
  content: "";
}

.bi-arrow-left:before {
  content: "";
}

.bi-arrow-repeat:before {
  content: "";
}

.bi-arrow-return-left:before {
  content: "";
}

.bi-arrow-return-right:before {
  content: "";
}

.bi-arrow-right-circle-fill:before {
  content: "";
}

.bi-arrow-right-circle:before {
  content: "";
}

.bi-arrow-right-short:before {
  content: "";
}

.bi-arrow-right-square-fill:before {
  content: "";
}

.bi-arrow-right-square:before {
  content: "";
}

.bi-arrow-right:before {
  content: "";
}

.bi-arrow-up-circle-fill:before {
  content: "";
}

.bi-arrow-up-circle:before {
  content: "";
}

.bi-arrow-up-left-circle-fill:before {
  content: "";
}

.bi-arrow-up-left-circle:before {
  content: "";
}

.bi-arrow-up-left-square-fill:before {
  content: "";
}

.bi-arrow-up-left-square:before {
  content: "";
}

.bi-arrow-up-left:before {
  content: "";
}

.bi-arrow-up-right-circle-fill:before {
  content: "";
}

.bi-arrow-up-right-circle:before {
  content: "";
}

.bi-arrow-up-right-square-fill:before {
  content: "";
}

.bi-arrow-up-right-square:before {
  content: "";
}

.bi-arrow-up-right:before {
  content: "";
}

.bi-arrow-up-short:before {
  content: "";
}

.bi-arrow-up-square-fill:before {
  content: "";
}

.bi-arrow-up-square:before {
  content: "";
}

.bi-arrow-up:before {
  content: "";
}

.bi-arrows-angle-contract:before {
  content: "";
}

.bi-arrows-angle-expand:before {
  content: "";
}

.bi-arrows-collapse:before {
  content: "";
}

.bi-arrows-expand:before {
  content: "";
}

.bi-arrows-fullscreen:before {
  content: "";
}

.bi-arrows-move:before {
  content: "";
}

.bi-aspect-ratio-fill:before {
  content: "";
}

.bi-aspect-ratio:before {
  content: "";
}

.bi-asterisk:before {
  content: "";
}

.bi-at:before {
  content: "";
}

.bi-award-fill:before {
  content: "";
}

.bi-award:before {
  content: "";
}

.bi-back:before {
  content: "";
}

.bi-backspace-fill:before {
  content: "";
}

.bi-backspace-reverse-fill:before {
  content: "";
}

.bi-backspace-reverse:before {
  content: "";
}

.bi-backspace:before {
  content: "";
}

.bi-badge-3d-fill:before {
  content: "";
}

.bi-badge-3d:before {
  content: "";
}

.bi-badge-4k-fill:before {
  content: "";
}

.bi-badge-4k:before {
  content: "";
}

.bi-badge-8k-fill:before {
  content: "";
}

.bi-badge-8k:before {
  content: "";
}

.bi-badge-ad-fill:before {
  content: "";
}

.bi-badge-ad:before {
  content: "";
}

.bi-badge-ar-fill:before {
  content: "";
}

.bi-badge-ar:before {
  content: "";
}

.bi-badge-cc-fill:before {
  content: "";
}

.bi-badge-cc:before {
  content: "";
}

.bi-badge-hd-fill:before {
  content: "";
}

.bi-badge-hd:before {
  content: "";
}

.bi-badge-tm-fill:before {
  content: "";
}

.bi-badge-tm:before {
  content: "";
}

.bi-badge-vo-fill:before {
  content: "";
}

.bi-badge-vo:before {
  content: "";
}

.bi-badge-vr-fill:before {
  content: "";
}

.bi-badge-vr:before {
  content: "";
}

.bi-badge-wc-fill:before {
  content: "";
}

.bi-badge-wc:before {
  content: "";
}

.bi-bag-check-fill:before {
  content: "";
}

.bi-bag-check:before {
  content: "";
}

.bi-bag-dash-fill:before {
  content: "";
}

.bi-bag-dash:before {
  content: "";
}

.bi-bag-fill:before {
  content: "";
}

.bi-bag-plus-fill:before {
  content: "";
}

.bi-bag-plus:before {
  content: "";
}

.bi-bag-x-fill:before {
  content: "";
}

.bi-bag-x:before {
  content: "";
}

.bi-bag:before {
  content: "";
}

.bi-bar-chart-fill:before {
  content: "";
}

.bi-bar-chart-line-fill:before {
  content: "";
}

.bi-bar-chart-line:before {
  content: "";
}

.bi-bar-chart-steps:before {
  content: "";
}

.bi-bar-chart:before {
  content: "";
}

.bi-basket-fill:before {
  content: "";
}

.bi-basket:before {
  content: "";
}

.bi-basket2-fill:before {
  content: "";
}

.bi-basket2:before {
  content: "";
}

.bi-basket3-fill:before {
  content: "";
}

.bi-basket3:before {
  content: "";
}

.bi-battery-charging:before {
  content: "";
}

.bi-battery-full:before {
  content: "";
}

.bi-battery-half:before {
  content: "";
}

.bi-battery:before {
  content: "";
}

.bi-bell-fill:before {
  content: "";
}

.bi-bell:before {
  content: "";
}

.bi-bezier:before {
  content: "";
}

.bi-bezier2:before {
  content: "";
}

.bi-bicycle:before {
  content: "";
}

.bi-binoculars-fill:before {
  content: "";
}

.bi-binoculars:before {
  content: "";
}

.bi-blockquote-left:before {
  content: "";
}

.bi-blockquote-right:before {
  content: "";
}

.bi-book-fill:before {
  content: "";
}

.bi-book-half:before {
  content: "";
}

.bi-book:before {
  content: "";
}

.bi-bookmark-check-fill:before {
  content: "";
}

.bi-bookmark-check:before {
  content: "";
}

.bi-bookmark-dash-fill:before {
  content: "";
}

.bi-bookmark-dash:before {
  content: "";
}

.bi-bookmark-fill:before {
  content: "";
}

.bi-bookmark-heart-fill:before {
  content: "";
}

.bi-bookmark-heart:before {
  content: "";
}

.bi-bookmark-plus-fill:before {
  content: "";
}

.bi-bookmark-plus:before {
  content: "";
}

.bi-bookmark-star-fill:before {
  content: "";
}

.bi-bookmark-star:before {
  content: "";
}

.bi-bookmark-x-fill:before {
  content: "";
}

.bi-bookmark-x:before {
  content: "";
}

.bi-bookmark:before {
  content: "";
}

.bi-bookmarks-fill:before {
  content: "";
}

.bi-bookmarks:before {
  content: "";
}

.bi-bookshelf:before {
  content: "";
}

.bi-bootstrap-fill:before {
  content: "";
}

.bi-bootstrap-reboot:before {
  content: "";
}

.bi-bootstrap:before {
  content: "";
}

.bi-border-all:before {
  content: "";
}

.bi-border-bottom:before {
  content: "";
}

.bi-border-center:before {
  content: "";
}

.bi-border-inner:before {
  content: "";
}

.bi-border-left:before {
  content: "";
}

.bi-border-middle:before {
  content: "";
}

.bi-border-outer:before {
  content: "";
}

.bi-border-right:before {
  content: "";
}

.bi-border-style:before {
  content: "";
}

.bi-border-top:before {
  content: "";
}

.bi-border-width:before {
  content: "";
}

.bi-border:before {
  content: "";
}

.bi-bounding-box-circles:before {
  content: "";
}

.bi-bounding-box:before {
  content: "";
}

.bi-box-arrow-down-left:before {
  content: "";
}

.bi-box-arrow-down-right:before {
  content: "";
}

.bi-box-arrow-down:before {
  content: "";
}

.bi-box-arrow-in-down-left:before {
  content: "";
}

.bi-box-arrow-in-down-right:before {
  content: "";
}

.bi-box-arrow-in-down:before {
  content: "";
}

.bi-box-arrow-in-left:before {
  content: "";
}

.bi-box-arrow-in-right:before {
  content: "";
}

.bi-box-arrow-in-up-left:before {
  content: "";
}

.bi-box-arrow-in-up-right:before {
  content: "";
}

.bi-box-arrow-in-up:before {
  content: "";
}

.bi-box-arrow-left:before {
  content: "";
}

.bi-box-arrow-right:before {
  content: "";
}

.bi-box-arrow-up-left:before {
  content: "";
}

.bi-box-arrow-up-right:before {
  content: "";
}

.bi-box-arrow-up:before {
  content: "";
}

.bi-box-seam:before {
  content: "";
}

.bi-box:before {
  content: "";
}

.bi-braces:before {
  content: "";
}

.bi-bricks:before {
  content: "";
}

.bi-briefcase-fill:before {
  content: "";
}

.bi-briefcase:before {
  content: "";
}

.bi-brightness-alt-high-fill:before {
  content: "";
}

.bi-brightness-alt-high:before {
  content: "";
}

.bi-brightness-alt-low-fill:before {
  content: "";
}

.bi-brightness-alt-low:before {
  content: "";
}

.bi-brightness-high-fill:before {
  content: "";
}

.bi-brightness-high:before {
  content: "";
}

.bi-brightness-low-fill:before {
  content: "";
}

.bi-brightness-low:before {
  content: "";
}

.bi-broadcast-pin:before {
  content: "";
}

.bi-broadcast:before {
  content: "";
}

.bi-brush-fill:before {
  content: "";
}

.bi-brush:before {
  content: "";
}

.bi-bucket-fill:before {
  content: "";
}

.bi-bucket:before {
  content: "";
}

.bi-bug-fill:before {
  content: "";
}

.bi-bug:before {
  content: "";
}

.bi-building:before {
  content: "";
}

.bi-bullseye:before {
  content: "";
}

.bi-calculator-fill:before {
  content: "";
}

.bi-calculator:before {
  content: "";
}

.bi-calendar-check-fill:before {
  content: "";
}

.bi-calendar-check:before {
  content: "";
}

.bi-calendar-date-fill:before {
  content: "";
}

.bi-calendar-date:before {
  content: "";
}

.bi-calendar-day-fill:before {
  content: "";
}

.bi-calendar-day:before {
  content: "";
}

.bi-calendar-event-fill:before {
  content: "";
}

.bi-calendar-event:before {
  content: "";
}

.bi-calendar-fill:before {
  content: "";
}

.bi-calendar-minus-fill:before {
  content: "";
}

.bi-calendar-minus:before {
  content: "";
}

.bi-calendar-month-fill:before {
  content: "";
}

.bi-calendar-month:before {
  content: "";
}

.bi-calendar-plus-fill:before {
  content: "";
}

.bi-calendar-plus:before {
  content: "";
}

.bi-calendar-range-fill:before {
  content: "";
}

.bi-calendar-range:before {
  content: "";
}

.bi-calendar-week-fill:before {
  content: "";
}

.bi-calendar-week:before {
  content: "";
}

.bi-calendar-x-fill:before {
  content: "";
}

.bi-calendar-x:before {
  content: "";
}

.bi-calendar:before {
  content: "";
}

.bi-calendar2-check-fill:before {
  content: "";
}

.bi-calendar2-check:before {
  content: "";
}

.bi-calendar2-date-fill:before {
  content: "";
}

.bi-calendar2-date:before {
  content: "";
}

.bi-calendar2-day-fill:before {
  content: "";
}

.bi-calendar2-day:before {
  content: "";
}

.bi-calendar2-event-fill:before {
  content: "";
}

.bi-calendar2-event:before {
  content: "";
}

.bi-calendar2-fill:before {
  content: "";
}

.bi-calendar2-minus-fill:before {
  content: "";
}

.bi-calendar2-minus:before {
  content: "";
}

.bi-calendar2-month-fill:before {
  content: "";
}

.bi-calendar2-month:before {
  content: "";
}

.bi-calendar2-plus-fill:before {
  content: "";
}

.bi-calendar2-plus:before {
  content: "";
}

.bi-calendar2-range-fill:before {
  content: "";
}

.bi-calendar2-range:before {
  content: "";
}

.bi-calendar2-week-fill:before {
  content: "";
}

.bi-calendar2-week:before {
  content: "";
}

.bi-calendar2-x-fill:before {
  content: "";
}

.bi-calendar2-x:before {
  content: "";
}

.bi-calendar2:before {
  content: "";
}

.bi-calendar3-event-fill:before {
  content: "";
}

.bi-calendar3-event:before {
  content: "";
}

.bi-calendar3-fill:before {
  content: "";
}

.bi-calendar3-range-fill:before {
  content: "";
}

.bi-calendar3-range:before {
  content: "";
}

.bi-calendar3-week-fill:before {
  content: "";
}

.bi-calendar3-week:before {
  content: "";
}

.bi-calendar3:before {
  content: "";
}

.bi-calendar4-event:before {
  content: "";
}

.bi-calendar4-range:before {
  content: "";
}

.bi-calendar4-week:before {
  content: "";
}

.bi-calendar4:before {
  content: "";
}

.bi-camera-fill:before {
  content: "";
}

.bi-camera-reels-fill:before {
  content: "";
}

.bi-camera-reels:before {
  content: "";
}

.bi-camera-video-fill:before {
  content: "";
}

.bi-camera-video-off-fill:before {
  content: "";
}

.bi-camera-video-off:before {
  content: "";
}

.bi-camera-video:before {
  content: "";
}

.bi-camera:before {
  content: "";
}

.bi-camera2:before {
  content: "";
}

.bi-capslock-fill:before {
  content: "";
}

.bi-capslock:before {
  content: "";
}

.bi-card-checklist:before {
  content: "";
}

.bi-card-heading:before {
  content: "";
}

.bi-card-image:before {
  content: "";
}

.bi-card-list:before {
  content: "";
}

.bi-card-text:before {
  content: "";
}

.bi-caret-down-fill:before {
  content: "";
}

.bi-caret-down-square-fill:before {
  content: "";
}

.bi-caret-down-square:before {
  content: "";
}

.bi-caret-down:before {
  content: "";
}

.bi-caret-left-fill:before {
  content: "";
}

.bi-caret-left-square-fill:before {
  content: "";
}

.bi-caret-left-square:before {
  content: "";
}

.bi-caret-left:before {
  content: "";
}

.bi-caret-right-fill:before {
  content: "";
}

.bi-caret-right-square-fill:before {
  content: "";
}

.bi-caret-right-square:before {
  content: "";
}

.bi-caret-right:before {
  content: "";
}

.bi-caret-up-fill:before {
  content: "";
}

.bi-caret-up-square-fill:before {
  content: "";
}

.bi-caret-up-square:before {
  content: "";
}

.bi-caret-up:before {
  content: "";
}

.bi-cart-check-fill:before {
  content: "";
}

.bi-cart-check:before {
  content: "";
}

.bi-cart-dash-fill:before {
  content: "";
}

.bi-cart-dash:before {
  content: "";
}

.bi-cart-fill:before {
  content: "";
}

.bi-cart-plus-fill:before {
  content: "";
}

.bi-cart-plus:before {
  content: "";
}

.bi-cart-x-fill:before {
  content: "";
}

.bi-cart-x:before {
  content: "";
}

.bi-cart:before {
  content: "";
}

.bi-cart2:before {
  content: "";
}

.bi-cart3:before {
  content: "";
}

.bi-cart4:before {
  content: "";
}

.bi-cash-stack:before {
  content: "";
}

.bi-cash:before {
  content: "";
}

.bi-cast:before {
  content: "";
}

.bi-chat-dots-fill:before {
  content: "";
}

.bi-chat-dots:before {
  content: "";
}

.bi-chat-fill:before {
  content: "";
}

.bi-chat-left-dots-fill:before {
  content: "";
}

.bi-chat-left-dots:before {
  content: "";
}

.bi-chat-left-fill:before {
  content: "";
}

.bi-chat-left-quote-fill:before {
  content: "";
}

.bi-chat-left-quote:before {
  content: "";
}

.bi-chat-left-text-fill:before {
  content: "";
}

.bi-chat-left-text:before {
  content: "";
}

.bi-chat-left:before {
  content: "";
}

.bi-chat-quote-fill:before {
  content: "";
}

.bi-chat-quote:before {
  content: "";
}

.bi-chat-right-dots-fill:before {
  content: "";
}

.bi-chat-right-dots:before {
  content: "";
}

.bi-chat-right-fill:before {
  content: "";
}

.bi-chat-right-quote-fill:before {
  content: "";
}

.bi-chat-right-quote:before {
  content: "";
}

.bi-chat-right-text-fill:before {
  content: "";
}

.bi-chat-right-text:before {
  content: "";
}

.bi-chat-right:before {
  content: "";
}

.bi-chat-square-dots-fill:before {
  content: "";
}

.bi-chat-square-dots:before {
  content: "";
}

.bi-chat-square-fill:before {
  content: "";
}

.bi-chat-square-quote-fill:before {
  content: "";
}

.bi-chat-square-quote:before {
  content: "";
}

.bi-chat-square-text-fill:before {
  content: "";
}

.bi-chat-square-text:before {
  content: "";
}

.bi-chat-square:before {
  content: "";
}

.bi-chat-text-fill:before {
  content: "";
}

.bi-chat-text:before {
  content: "";
}

.bi-chat:before {
  content: "";
}

.bi-check-all:before {
  content: "";
}

.bi-check-circle-fill:before {
  content: "";
}

.bi-check-circle:before {
  content: "";
}

.bi-check-square-fill:before {
  content: "";
}

.bi-check-square:before {
  content: "";
}

.bi-check:before {
  content: "";
}

.bi-check2-all:before {
  content: "";
}

.bi-check2-circle:before {
  content: "";
}

.bi-check2-square:before {
  content: "";
}

.bi-check2:before {
  content: "";
}

.bi-chevron-bar-contract:before {
  content: "";
}

.bi-chevron-bar-down:before {
  content: "";
}

.bi-chevron-bar-expand:before {
  content: "";
}

.bi-chevron-bar-left:before {
  content: "";
}

.bi-chevron-bar-right:before {
  content: "";
}

.bi-chevron-bar-up:before {
  content: "";
}

.bi-chevron-compact-down:before {
  content: "";
}

.bi-chevron-compact-left:before {
  content: "";
}

.bi-chevron-compact-right:before {
  content: "";
}

.bi-chevron-compact-up:before {
  content: "";
}

.bi-chevron-contract:before {
  content: "";
}

.bi-chevron-double-down:before {
  content: "";
}

.bi-chevron-double-left:before {
  content: "";
}

.bi-chevron-double-right:before {
  content: "";
}

.bi-chevron-double-up:before {
  content: "";
}

.bi-chevron-down:before {
  content: "";
}

.bi-chevron-expand:before {
  content: "";
}

.bi-chevron-left:before {
  content: "";
}

.bi-chevron-right:before {
  content: "";
}

.bi-chevron-up:before {
  content: "";
}

.bi-circle-fill:before {
  content: "";
}

.bi-circle-half:before {
  content: "";
}

.bi-circle-square:before {
  content: "";
}

.bi-circle:before {
  content: "";
}

.bi-clipboard-check:before {
  content: "";
}

.bi-clipboard-data:before {
  content: "";
}

.bi-clipboard-minus:before {
  content: "";
}

.bi-clipboard-plus:before {
  content: "";
}

.bi-clipboard-x:before {
  content: "";
}

.bi-clipboard:before {
  content: "";
}

.bi-clock-fill:before {
  content: "";
}

.bi-clock-history:before {
  content: "";
}

.bi-clock:before {
  content: "";
}

.bi-cloud-arrow-down-fill:before {
  content: "";
}

.bi-cloud-arrow-down:before {
  content: "";
}

.bi-cloud-arrow-up-fill:before {
  content: "";
}

.bi-cloud-arrow-up:before {
  content: "";
}

.bi-cloud-check-fill:before {
  content: "";
}

.bi-cloud-check:before {
  content: "";
}

.bi-cloud-download-fill:before {
  content: "";
}

.bi-cloud-download:before {
  content: "";
}

.bi-cloud-drizzle-fill:before {
  content: "";
}

.bi-cloud-drizzle:before {
  content: "";
}

.bi-cloud-fill:before {
  content: "";
}

.bi-cloud-fog-fill:before {
  content: "";
}

.bi-cloud-fog:before {
  content: "";
}

.bi-cloud-fog2-fill:before {
  content: "";
}

.bi-cloud-fog2:before {
  content: "";
}

.bi-cloud-hail-fill:before {
  content: "";
}

.bi-cloud-hail:before {
  content: "";
}

.bi-cloud-haze-fill:before {
  content: "";
}

.bi-cloud-haze:before {
  content: "";
}

.bi-cloud-haze2-fill:before {
  content: "";
}

.bi-cloud-lightning-fill:before {
  content: "";
}

.bi-cloud-lightning-rain-fill:before {
  content: "";
}

.bi-cloud-lightning-rain:before {
  content: "";
}

.bi-cloud-lightning:before {
  content: "";
}

.bi-cloud-minus-fill:before {
  content: "";
}

.bi-cloud-minus:before {
  content: "";
}

.bi-cloud-moon-fill:before {
  content: "";
}

.bi-cloud-moon:before {
  content: "";
}

.bi-cloud-plus-fill:before {
  content: "";
}

.bi-cloud-plus:before {
  content: "";
}

.bi-cloud-rain-fill:before {
  content: "";
}

.bi-cloud-rain-heavy-fill:before {
  content: "";
}

.bi-cloud-rain-heavy:before {
  content: "";
}

.bi-cloud-rain:before {
  content: "";
}

.bi-cloud-slash-fill:before {
  content: "";
}

.bi-cloud-slash:before {
  content: "";
}

.bi-cloud-sleet-fill:before {
  content: "";
}

.bi-cloud-sleet:before {
  content: "";
}

.bi-cloud-snow-fill:before {
  content: "";
}

.bi-cloud-snow:before {
  content: "";
}

.bi-cloud-sun-fill:before {
  content: "";
}

.bi-cloud-sun:before {
  content: "";
}

.bi-cloud-upload-fill:before {
  content: "";
}

.bi-cloud-upload:before {
  content: "";
}

.bi-cloud:before {
  content: "";
}

.bi-clouds-fill:before {
  content: "";
}

.bi-clouds:before {
  content: "";
}

.bi-cloudy-fill:before {
  content: "";
}

.bi-cloudy:before {
  content: "";
}

.bi-code-slash:before {
  content: "";
}

.bi-code-square:before {
  content: "";
}

.bi-code:before {
  content: "";
}

.bi-collection-fill:before {
  content: "";
}

.bi-collection-play-fill:before {
  content: "";
}

.bi-collection-play:before {
  content: "";
}

.bi-collection:before {
  content: "";
}

.bi-columns-gap:before {
  content: "";
}

.bi-columns:before {
  content: "";
}

.bi-command:before {
  content: "";
}

.bi-compass-fill:before {
  content: "";
}

.bi-compass:before {
  content: "";
}

.bi-cone-striped:before {
  content: "";
}

.bi-cone:before {
  content: "";
}

.bi-controller:before {
  content: "";
}

.bi-cpu-fill:before {
  content: "";
}

.bi-cpu:before {
  content: "";
}

.bi-credit-card-2-back-fill:before {
  content: "";
}

.bi-credit-card-2-back:before {
  content: "";
}

.bi-credit-card-2-front-fill:before {
  content: "";
}

.bi-credit-card-2-front:before {
  content: "";
}

.bi-credit-card-fill:before {
  content: "";
}

.bi-credit-card:before {
  content: "";
}

.bi-crop:before {
  content: "";
}

.bi-cup-fill:before {
  content: "";
}

.bi-cup-straw:before {
  content: "";
}

.bi-cup:before {
  content: "";
}

.bi-cursor-fill:before {
  content: "";
}

.bi-cursor-text:before {
  content: "";
}

.bi-cursor:before {
  content: "";
}

.bi-dash-circle-dotted:before {
  content: "";
}

.bi-dash-circle-fill:before {
  content: "";
}

.bi-dash-circle:before {
  content: "";
}

.bi-dash-square-dotted:before {
  content: "";
}

.bi-dash-square-fill:before {
  content: "";
}

.bi-dash-square:before {
  content: "";
}

.bi-dash:before {
  content: "";
}

.bi-diagram-2-fill:before {
  content: "";
}

.bi-diagram-2:before {
  content: "";
}

.bi-diagram-3-fill:before {
  content: "";
}

.bi-diagram-3:before {
  content: "";
}

.bi-diamond-fill:before {
  content: "";
}

.bi-diamond-half:before {
  content: "";
}

.bi-diamond:before {
  content: "";
}

.bi-dice-1-fill:before {
  content: "";
}

.bi-dice-1:before {
  content: "";
}

.bi-dice-2-fill:before {
  content: "";
}

.bi-dice-2:before {
  content: "";
}

.bi-dice-3-fill:before {
  content: "";
}

.bi-dice-3:before {
  content: "";
}

.bi-dice-4-fill:before {
  content: "";
}

.bi-dice-4:before {
  content: "";
}

.bi-dice-5-fill:before {
  content: "";
}

.bi-dice-5:before {
  content: "";
}

.bi-dice-6-fill:before {
  content: "";
}

.bi-dice-6:before {
  content: "";
}

.bi-disc-fill:before {
  content: "";
}

.bi-disc:before {
  content: "";
}

.bi-discord:before {
  content: "";
}

.bi-display-fill:before {
  content: "";
}

.bi-display:before {
  content: "";
}

.bi-distribute-horizontal:before {
  content: "";
}

.bi-distribute-vertical:before {
  content: "";
}

.bi-door-closed-fill:before {
  content: "";
}

.bi-door-closed:before {
  content: "";
}

.bi-door-open-fill:before {
  content: "";
}

.bi-door-open:before {
  content: "";
}

.bi-dot:before {
  content: "";
}

.bi-download:before {
  content: "";
}

.bi-droplet-fill:before {
  content: "";
}

.bi-droplet-half:before {
  content: "";
}

.bi-droplet:before {
  content: "";
}

.bi-earbuds:before {
  content: "";
}

.bi-easel-fill:before {
  content: "";
}

.bi-easel:before {
  content: "";
}

.bi-egg-fill:before {
  content: "";
}

.bi-egg-fried:before {
  content: "";
}

.bi-egg:before {
  content: "";
}

.bi-eject-fill:before {
  content: "";
}

.bi-eject:before {
  content: "";
}

.bi-emoji-angry-fill:before {
  content: "";
}

.bi-emoji-angry:before {
  content: "";
}

.bi-emoji-dizzy-fill:before {
  content: "";
}

.bi-emoji-dizzy:before {
  content: "";
}

.bi-emoji-expressionless-fill:before {
  content: "";
}

.bi-emoji-expressionless:before {
  content: "";
}

.bi-emoji-frown-fill:before {
  content: "";
}

.bi-emoji-frown:before {
  content: "";
}

.bi-emoji-heart-eyes-fill:before {
  content: "";
}

.bi-emoji-heart-eyes:before {
  content: "";
}

.bi-emoji-laughing-fill:before {
  content: "";
}

.bi-emoji-laughing:before {
  content: "";
}

.bi-emoji-neutral-fill:before {
  content: "";
}

.bi-emoji-neutral:before {
  content: "";
}

.bi-emoji-smile-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down:before {
  content: "";
}

.bi-emoji-smile:before {
  content: "";
}

.bi-emoji-sunglasses-fill:before {
  content: "";
}

.bi-emoji-sunglasses:before {
  content: "";
}

.bi-emoji-wink-fill:before {
  content: "";
}

.bi-emoji-wink:before {
  content: "";
}

.bi-envelope-fill:before {
  content: "";
}

.bi-envelope-open-fill:before {
  content: "";
}

.bi-envelope-open:before {
  content: "";
}

.bi-envelope:before {
  content: "";
}

.bi-eraser-fill:before {
  content: "";
}

.bi-eraser:before {
  content: "";
}

.bi-exclamation-circle-fill:before {
  content: "";
}

.bi-exclamation-circle:before {
  content: "";
}

.bi-exclamation-diamond-fill:before {
  content: "";
}

.bi-exclamation-diamond:before {
  content: "";
}

.bi-exclamation-octagon-fill:before {
  content: "";
}

.bi-exclamation-octagon:before {
  content: "";
}

.bi-exclamation-square-fill:before {
  content: "";
}

.bi-exclamation-square:before {
  content: "";
}

.bi-exclamation-triangle-fill:before {
  content: "";
}

.bi-exclamation-triangle:before {
  content: "";
}

.bi-exclamation:before {
  content: "";
}

.bi-exclude:before {
  content: "";
}

.bi-eye-fill:before {
  content: "";
}

.bi-eye-slash-fill:before {
  content: "";
}

.bi-eye-slash:before {
  content: "";
}

.bi-eye:before {
  content: "";
}

.bi-eyedropper:before {
  content: "";
}

.bi-eyeglasses:before {
  content: "";
}

.bi-facebook:before {
  content: "";
}

.bi-file-arrow-down-fill:before {
  content: "";
}

.bi-file-arrow-down:before {
  content: "";
}

.bi-file-arrow-up-fill:before {
  content: "";
}

.bi-file-arrow-up:before {
  content: "";
}

.bi-file-bar-graph-fill:before {
  content: "";
}

.bi-file-bar-graph:before {
  content: "";
}

.bi-file-binary-fill:before {
  content: "";
}

.bi-file-binary:before {
  content: "";
}

.bi-file-break-fill:before {
  content: "";
}

.bi-file-break:before {
  content: "";
}

.bi-file-check-fill:before {
  content: "";
}

.bi-file-check:before {
  content: "";
}

.bi-file-code-fill:before {
  content: "";
}

.bi-file-code:before {
  content: "";
}

.bi-file-diff-fill:before {
  content: "";
}

.bi-file-diff:before {
  content: "";
}

.bi-file-earmark-arrow-down-fill:before {
  content: "";
}

.bi-file-earmark-arrow-down:before {
  content: "";
}

.bi-file-earmark-arrow-up-fill:before {
  content: "";
}

.bi-file-earmark-arrow-up:before {
  content: "";
}

.bi-file-earmark-bar-graph-fill:before {
  content: "";
}

.bi-file-earmark-bar-graph:before {
  content: "";
}

.bi-file-earmark-binary-fill:before {
  content: "";
}

.bi-file-earmark-binary:before {
  content: "";
}

.bi-file-earmark-break-fill:before {
  content: "";
}

.bi-file-earmark-break:before {
  content: "";
}

.bi-file-earmark-check-fill:before {
  content: "";
}

.bi-file-earmark-check:before {
  content: "";
}

.bi-file-earmark-code-fill:before {
  content: "";
}

.bi-file-earmark-code:before {
  content: "";
}

.bi-file-earmark-diff-fill:before {
  content: "";
}

.bi-file-earmark-diff:before {
  content: "";
}

.bi-file-earmark-easel-fill:before {
  content: "";
}

.bi-file-earmark-easel:before {
  content: "";
}

.bi-file-earmark-excel-fill:before {
  content: "";
}

.bi-file-earmark-excel:before {
  content: "";
}

.bi-file-earmark-fill:before {
  content: "";
}

.bi-file-earmark-font-fill:before {
  content: "";
}

.bi-file-earmark-font:before {
  content: "";
}

.bi-file-earmark-image-fill:before {
  content: "";
}

.bi-file-earmark-image:before {
  content: "";
}

.bi-file-earmark-lock-fill:before {
  content: "";
}

.bi-file-earmark-lock:before {
  content: "";
}

.bi-file-earmark-lock2-fill:before {
  content: "";
}

.bi-file-earmark-lock2:before {
  content: "";
}

.bi-file-earmark-medical-fill:before {
  content: "";
}

.bi-file-earmark-medical:before {
  content: "";
}

.bi-file-earmark-minus-fill:before {
  content: "";
}

.bi-file-earmark-minus:before {
  content: "";
}

.bi-file-earmark-music-fill:before {
  content: "";
}

.bi-file-earmark-music:before {
  content: "";
}

.bi-file-earmark-person-fill:before {
  content: "";
}

.bi-file-earmark-person:before {
  content: "";
}

.bi-file-earmark-play-fill:before {
  content: "";
}

.bi-file-earmark-play:before {
  content: "";
}

.bi-file-earmark-plus-fill:before {
  content: "";
}

.bi-file-earmark-plus:before {
  content: "";
}

.bi-file-earmark-post-fill:before {
  content: "";
}

.bi-file-earmark-post:before {
  content: "";
}

.bi-file-earmark-ppt-fill:before {
  content: "";
}

.bi-file-earmark-ppt:before {
  content: "";
}

.bi-file-earmark-richtext-fill:before {
  content: "";
}

.bi-file-earmark-richtext:before {
  content: "";
}

.bi-file-earmark-ruled-fill:before {
  content: "";
}

.bi-file-earmark-ruled:before {
  content: "";
}

.bi-file-earmark-slides-fill:before {
  content: "";
}

.bi-file-earmark-slides:before {
  content: "";
}

.bi-file-earmark-spreadsheet-fill:before {
  content: "";
}

.bi-file-earmark-spreadsheet:before {
  content: "";
}

.bi-file-earmark-text-fill:before {
  content: "";
}

.bi-file-earmark-text:before {
  content: "";
}

.bi-file-earmark-word-fill:before {
  content: "";
}

.bi-file-earmark-word:before {
  content: "";
}

.bi-file-earmark-x-fill:before {
  content: "";
}

.bi-file-earmark-x:before {
  content: "";
}

.bi-file-earmark-zip-fill:before {
  content: "";
}

.bi-file-earmark-zip:before {
  content: "";
}

.bi-file-earmark:before {
  content: "";
}

.bi-file-easel-fill:before {
  content: "";
}

.bi-file-easel:before {
  content: "";
}

.bi-file-excel-fill:before {
  content: "";
}

.bi-file-excel:before {
  content: "";
}

.bi-file-fill:before {
  content: "";
}

.bi-file-font-fill:before {
  content: "";
}

.bi-file-font:before {
  content: "";
}

.bi-file-image-fill:before {
  content: "";
}

.bi-file-image:before {
  content: "";
}

.bi-file-lock-fill:before {
  content: "";
}

.bi-file-lock:before {
  content: "";
}

.bi-file-lock2-fill:before {
  content: "";
}

.bi-file-lock2:before {
  content: "";
}

.bi-file-medical-fill:before {
  content: "";
}

.bi-file-medical:before {
  content: "";
}

.bi-file-minus-fill:before {
  content: "";
}

.bi-file-minus:before {
  content: "";
}

.bi-file-music-fill:before {
  content: "";
}

.bi-file-music:before {
  content: "";
}

.bi-file-person-fill:before {
  content: "";
}

.bi-file-person:before {
  content: "";
}

.bi-file-play-fill:before {
  content: "";
}

.bi-file-play:before {
  content: "";
}

.bi-file-plus-fill:before {
  content: "";
}

.bi-file-plus:before {
  content: "";
}

.bi-file-post-fill:before {
  content: "";
}

.bi-file-post:before {
  content: "";
}

.bi-file-ppt-fill:before {
  content: "";
}

.bi-file-ppt:before {
  content: "";
}

.bi-file-richtext-fill:before {
  content: "";
}

.bi-file-richtext:before {
  content: "";
}

.bi-file-ruled-fill:before {
  content: "";
}

.bi-file-ruled:before {
  content: "";
}

.bi-file-slides-fill:before {
  content: "";
}

.bi-file-slides:before {
  content: "";
}

.bi-file-spreadsheet-fill:before {
  content: "";
}

.bi-file-spreadsheet:before {
  content: "";
}

.bi-file-text-fill:before {
  content: "";
}

.bi-file-text:before {
  content: "";
}

.bi-file-word-fill:before {
  content: "";
}

.bi-file-word:before {
  content: "";
}

.bi-file-x-fill:before {
  content: "";
}

.bi-file-x:before {
  content: "";
}

.bi-file-zip-fill:before {
  content: "";
}

.bi-file-zip:before {
  content: "";
}

.bi-file:before {
  content: "";
}

.bi-files-alt:before {
  content: "";
}

.bi-files:before {
  content: "";
}

.bi-film:before {
  content: "";
}

.bi-filter-circle-fill:before {
  content: "";
}

.bi-filter-circle:before {
  content: "";
}

.bi-filter-left:before {
  content: "";
}

.bi-filter-right:before {
  content: "";
}

.bi-filter-square-fill:before {
  content: "";
}

.bi-filter-square:before {
  content: "";
}

.bi-filter:before {
  content: "";
}

.bi-flag-fill:before {
  content: "";
}

.bi-flag:before {
  content: "";
}

.bi-flower1:before {
  content: "";
}

.bi-flower2:before {
  content: "";
}

.bi-flower3:before {
  content: "";
}

.bi-folder-check:before {
  content: "";
}

.bi-folder-fill:before {
  content: "";
}

.bi-folder-minus:before {
  content: "";
}

.bi-folder-plus:before {
  content: "";
}

.bi-folder-symlink-fill:before {
  content: "";
}

.bi-folder-symlink:before {
  content: "";
}

.bi-folder-x:before {
  content: "";
}

.bi-folder:before {
  content: "";
}

.bi-folder2-open:before {
  content: "";
}

.bi-folder2:before {
  content: "";
}

.bi-fonts:before {
  content: "";
}

.bi-forward-fill:before {
  content: "";
}

.bi-forward:before {
  content: "";
}

.bi-front:before {
  content: "";
}

.bi-fullscreen-exit:before {
  content: "";
}

.bi-fullscreen:before {
  content: "";
}

.bi-funnel-fill:before {
  content: "";
}

.bi-funnel:before {
  content: "";
}

.bi-gear-fill:before {
  content: "";
}

.bi-gear-wide-connected:before {
  content: "";
}

.bi-gear-wide:before {
  content: "";
}

.bi-gear:before {
  content: "";
}

.bi-gem:before {
  content: "";
}

.bi-geo-alt-fill:before {
  content: "";
}

.bi-geo-alt:before {
  content: "";
}

.bi-geo-fill:before {
  content: "";
}

.bi-geo:before {
  content: "";
}

.bi-gift-fill:before {
  content: "";
}

.bi-gift:before {
  content: "";
}

.bi-github:before {
  content: "";
}

.bi-globe:before {
  content: "";
}

.bi-globe2:before {
  content: "";
}

.bi-google:before {
  content: "";
}

.bi-graph-down:before {
  content: "";
}

.bi-graph-up:before {
  content: "";
}

.bi-grid-1x2-fill:before {
  content: "";
}

.bi-grid-1x2:before {
  content: "";
}

.bi-grid-3x2-gap-fill:before {
  content: "";
}

.bi-grid-3x2-gap:before {
  content: "";
}

.bi-grid-3x2:before {
  content: "";
}

.bi-grid-3x3-gap-fill:before {
  content: "";
}

.bi-grid-3x3-gap:before {
  content: "";
}

.bi-grid-3x3:before {
  content: "";
}

.bi-grid-fill:before {
  content: "";
}

.bi-grid:before {
  content: "";
}

.bi-grip-horizontal:before {
  content: "";
}

.bi-grip-vertical:before {
  content: "";
}

.bi-hammer:before {
  content: "";
}

.bi-hand-index-fill:before {
  content: "";
}

.bi-hand-index-thumb-fill:before {
  content: "";
}

.bi-hand-index-thumb:before {
  content: "";
}

.bi-hand-index:before {
  content: "";
}

.bi-hand-thumbs-down-fill:before {
  content: "";
}

.bi-hand-thumbs-down:before {
  content: "";
}

.bi-hand-thumbs-up-fill:before {
  content: "";
}

.bi-hand-thumbs-up:before {
  content: "";
}

.bi-handbag-fill:before {
  content: "";
}

.bi-handbag:before {
  content: "";
}

.bi-hash:before {
  content: "";
}

.bi-hdd-fill:before {
  content: "";
}

.bi-hdd-network-fill:before {
  content: "";
}

.bi-hdd-network:before {
  content: "";
}

.bi-hdd-rack-fill:before {
  content: "";
}

.bi-hdd-rack:before {
  content: "";
}

.bi-hdd-stack-fill:before {
  content: "";
}

.bi-hdd-stack:before {
  content: "";
}

.bi-hdd:before {
  content: "";
}

.bi-headphones:before {
  content: "";
}

.bi-headset:before {
  content: "";
}

.bi-heart-fill:before {
  content: "";
}

.bi-heart-half:before {
  content: "";
}

.bi-heart:before {
  content: "";
}

.bi-heptagon-fill:before {
  content: "";
}

.bi-heptagon-half:before {
  content: "";
}

.bi-heptagon:before {
  content: "";
}

.bi-hexagon-fill:before {
  content: "";
}

.bi-hexagon-half:before {
  content: "";
}

.bi-hexagon:before {
  content: "";
}

.bi-hourglass-bottom:before {
  content: "";
}

.bi-hourglass-split:before {
  content: "";
}

.bi-hourglass-top:before {
  content: "";
}

.bi-hourglass:before {
  content: "";
}

.bi-house-door-fill:before {
  content: "";
}

.bi-house-door:before {
  content: "";
}

.bi-house-fill:before {
  content: "";
}

.bi-house:before {
  content: "";
}

.bi-hr:before {
  content: "";
}

.bi-hurricane:before {
  content: "";
}

.bi-image-alt:before {
  content: "";
}

.bi-image-fill:before {
  content: "";
}

.bi-image:before {
  content: "";
}

.bi-images:before {
  content: "";
}

.bi-inbox-fill:before {
  content: "";
}

.bi-inbox:before {
  content: "";
}

.bi-inboxes-fill:before {
  content: "";
}

.bi-inboxes:before {
  content: "";
}

.bi-info-circle-fill:before {
  content: "";
}

.bi-info-circle:before {
  content: "";
}

.bi-info-square-fill:before {
  content: "";
}

.bi-info-square:before {
  content: "";
}

.bi-info:before {
  content: "";
}

.bi-input-cursor-text:before {
  content: "";
}

.bi-input-cursor:before {
  content: "";
}

.bi-instagram:before {
  content: "";
}

.bi-intersect:before {
  content: "";
}

.bi-journal-album:before {
  content: "";
}

.bi-journal-arrow-down:before {
  content: "";
}

.bi-journal-arrow-up:before {
  content: "";
}

.bi-journal-bookmark-fill:before {
  content: "";
}

.bi-journal-bookmark:before {
  content: "";
}

.bi-journal-check:before {
  content: "";
}

.bi-journal-code:before {
  content: "";
}

.bi-journal-medical:before {
  content: "";
}

.bi-journal-minus:before {
  content: "";
}

.bi-journal-plus:before {
  content: "";
}

.bi-journal-richtext:before {
  content: "";
}

.bi-journal-text:before {
  content: "";
}

.bi-journal-x:before {
  content: "";
}

.bi-journal:before {
  content: "";
}

.bi-journals:before {
  content: "";
}

.bi-joystick:before {
  content: "";
}

.bi-justify-left:before {
  content: "";
}

.bi-justify-right:before {
  content: "";
}

.bi-justify:before {
  content: "";
}

.bi-kanban-fill:before {
  content: "";
}

.bi-kanban:before {
  content: "";
}

.bi-key-fill:before {
  content: "";
}

.bi-key:before {
  content: "";
}

.bi-keyboard-fill:before {
  content: "";
}

.bi-keyboard:before {
  content: "";
}

.bi-ladder:before {
  content: "";
}

.bi-lamp-fill:before {
  content: "";
}

.bi-lamp:before {
  content: "";
}

.bi-laptop-fill:before {
  content: "";
}

.bi-laptop:before {
  content: "";
}

.bi-layer-backward:before {
  content: "";
}

.bi-layer-forward:before {
  content: "";
}

.bi-layers-fill:before {
  content: "";
}

.bi-layers-half:before {
  content: "";
}

.bi-layers:before {
  content: "";
}

.bi-layout-sidebar-inset-reverse:before {
  content: "";
}

.bi-layout-sidebar-inset:before {
  content: "";
}

.bi-layout-sidebar-reverse:before {
  content: "";
}

.bi-layout-sidebar:before {
  content: "";
}

.bi-layout-split:before {
  content: "";
}

.bi-layout-text-sidebar-reverse:before {
  content: "";
}

.bi-layout-text-sidebar:before {
  content: "";
}

.bi-layout-text-window-reverse:before {
  content: "";
}

.bi-layout-text-window:before {
  content: "";
}

.bi-layout-three-columns:before {
  content: "";
}

.bi-layout-wtf:before {
  content: "";
}

.bi-life-preserver:before {
  content: "";
}

.bi-lightbulb-fill:before {
  content: "";
}

.bi-lightbulb-off-fill:before {
  content: "";
}

.bi-lightbulb-off:before {
  content: "";
}

.bi-lightbulb:before {
  content: "";
}

.bi-lightning-charge-fill:before {
  content: "";
}

.bi-lightning-charge:before {
  content: "";
}

.bi-lightning-fill:before {
  content: "";
}

.bi-lightning:before {
  content: "";
}

.bi-link-45deg:before {
  content: "";
}

.bi-link:before {
  content: "";
}

.bi-linkedin:before {
  content: "";
}

.bi-list-check:before {
  content: "";
}

.bi-list-nested:before {
  content: "";
}

.bi-list-ol:before {
  content: "";
}

.bi-list-stars:before {
  content: "";
}

.bi-list-task:before {
  content: "";
}

.bi-list-ul:before {
  content: "";
}

.bi-list:before {
  content: "";
}

.bi-lock-fill:before {
  content: "";
}

.bi-lock:before {
  content: "";
}

.bi-mailbox:before {
  content: "";
}

.bi-mailbox2:before {
  content: "";
}

.bi-map-fill:before {
  content: "";
}

.bi-map:before {
  content: "";
}

.bi-markdown-fill:before {
  content: "";
}

.bi-markdown:before {
  content: "";
}

.bi-mask:before {
  content: "";
}

.bi-megaphone-fill:before {
  content: "";
}

.bi-megaphone:before {
  content: "";
}

.bi-menu-app-fill:before {
  content: "";
}

.bi-menu-app:before {
  content: "";
}

.bi-menu-button-fill:before {
  content: "";
}

.bi-menu-button-wide-fill:before {
  content: "";
}

.bi-menu-button-wide:before {
  content: "";
}

.bi-menu-button:before {
  content: "";
}

.bi-menu-down:before {
  content: "";
}

.bi-menu-up:before {
  content: "";
}

.bi-mic-fill:before {
  content: "";
}

.bi-mic-mute-fill:before {
  content: "";
}

.bi-mic-mute:before {
  content: "";
}

.bi-mic:before {
  content: "";
}

.bi-minecart-loaded:before {
  content: "";
}

.bi-minecart:before {
  content: "";
}

.bi-moisture:before {
  content: "";
}

.bi-moon-fill:before {
  content: "";
}

.bi-moon-stars-fill:before {
  content: "";
}

.bi-moon-stars:before {
  content: "";
}

.bi-moon:before {
  content: "";
}

.bi-mouse-fill:before {
  content: "";
}

.bi-mouse:before {
  content: "";
}

.bi-mouse2-fill:before {
  content: "";
}

.bi-mouse2:before {
  content: "";
}

.bi-mouse3-fill:before {
  content: "";
}

.bi-mouse3:before {
  content: "";
}

.bi-music-note-beamed:before {
  content: "";
}

.bi-music-note-list:before {
  content: "";
}

.bi-music-note:before {
  content: "";
}

.bi-music-player-fill:before {
  content: "";
}

.bi-music-player:before {
  content: "";
}

.bi-newspaper:before {
  content: "";
}

.bi-node-minus-fill:before {
  content: "";
}

.bi-node-minus:before {
  content: "";
}

.bi-node-plus-fill:before {
  content: "";
}

.bi-node-plus:before {
  content: "";
}

.bi-nut-fill:before {
  content: "";
}

.bi-nut:before {
  content: "";
}

.bi-octagon-fill:before {
  content: "";
}

.bi-octagon-half:before {
  content: "";
}

.bi-octagon:before {
  content: "";
}

.bi-option:before {
  content: "";
}

.bi-outlet:before {
  content: "";
}

.bi-paint-bucket:before {
  content: "";
}

.bi-palette-fill:before {
  content: "";
}

.bi-palette:before {
  content: "";
}

.bi-palette2:before {
  content: "";
}

.bi-paperclip:before {
  content: "";
}

.bi-paragraph:before {
  content: "";
}

.bi-patch-check-fill:before {
  content: "";
}

.bi-patch-check:before {
  content: "";
}

.bi-patch-exclamation-fill:before {
  content: "";
}

.bi-patch-exclamation:before {
  content: "";
}

.bi-patch-minus-fill:before {
  content: "";
}

.bi-patch-minus:before {
  content: "";
}

.bi-patch-plus-fill:before {
  content: "";
}

.bi-patch-plus:before {
  content: "";
}

.bi-patch-question-fill:before {
  content: "";
}

.bi-patch-question:before {
  content: "";
}

.bi-pause-btn-fill:before {
  content: "";
}

.bi-pause-btn:before {
  content: "";
}

.bi-pause-circle-fill:before {
  content: "";
}

.bi-pause-circle:before {
  content: "";
}

.bi-pause-fill:before {
  content: "";
}

.bi-pause:before {
  content: "";
}

.bi-peace-fill:before {
  content: "";
}

.bi-peace:before {
  content: "";
}

.bi-pen-fill:before {
  content: "";
}

.bi-pen:before {
  content: "";
}

.bi-pencil-fill:before {
  content: "";
}

.bi-pencil-square:before {
  content: "";
}

.bi-pencil:before {
  content: "";
}

.bi-pentagon-fill:before {
  content: "";
}

.bi-pentagon-half:before {
  content: "";
}

.bi-pentagon:before {
  content: "";
}

.bi-people-fill:before {
  content: "";
}

.bi-people:before {
  content: "";
}

.bi-percent:before {
  content: "";
}

.bi-person-badge-fill:before {
  content: "";
}

.bi-person-badge:before {
  content: "";
}

.bi-person-bounding-box:before {
  content: "";
}

.bi-person-check-fill:before {
  content: "";
}

.bi-person-check:before {
  content: "";
}

.bi-person-circle:before {
  content: "";
}

.bi-person-dash-fill:before {
  content: "";
}

.bi-person-dash:before {
  content: "";
}

.bi-person-fill:before {
  content: "";
}

.bi-person-lines-fill:before {
  content: "";
}

.bi-person-plus-fill:before {
  content: "";
}

.bi-person-plus:before {
  content: "";
}

.bi-person-square:before {
  content: "";
}

.bi-person-x-fill:before {
  content: "";
}

.bi-person-x:before {
  content: "";
}

.bi-person:before {
  content: "";
}

.bi-phone-fill:before {
  content: "";
}

.bi-phone-landscape-fill:before {
  content: "";
}

.bi-phone-landscape:before {
  content: "";
}

.bi-phone-vibrate-fill:before {
  content: "";
}

.bi-phone-vibrate:before {
  content: "";
}

.bi-phone:before {
  content: "";
}

.bi-pie-chart-fill:before {
  content: "";
}

.bi-pie-chart:before {
  content: "";
}

.bi-pin-angle-fill:before {
  content: "";
}

.bi-pin-angle:before {
  content: "";
}

.bi-pin-fill:before {
  content: "";
}

.bi-pin:before {
  content: "";
}

.bi-pip-fill:before {
  content: "";
}

.bi-pip:before {
  content: "";
}

.bi-play-btn-fill:before {
  content: "";
}

.bi-play-btn:before {
  content: "";
}

.bi-play-circle-fill:before {
  content: "";
}

.bi-play-circle:before {
  content: "";
}

.bi-play-fill:before {
  content: "";
}

.bi-play:before {
  content: "";
}

.bi-plug-fill:before {
  content: "";
}

.bi-plug:before {
  content: "";
}

.bi-plus-circle-dotted:before {
  content: "";
}

.bi-plus-circle-fill:before {
  content: "";
}

.bi-plus-circle:before {
  content: "";
}

.bi-plus-square-dotted:before {
  content: "";
}

.bi-plus-square-fill:before {
  content: "";
}

.bi-plus-square:before {
  content: "";
}

.bi-plus:before {
  content: "";
}

.bi-power:before {
  content: "";
}

.bi-printer-fill:before {
  content: "";
}

.bi-printer:before {
  content: "";
}

.bi-puzzle-fill:before {
  content: "";
}

.bi-puzzle:before {
  content: "";
}

.bi-question-circle-fill:before {
  content: "";
}

.bi-question-circle:before {
  content: "";
}

.bi-question-diamond-fill:before {
  content: "";
}

.bi-question-diamond:before {
  content: "";
}

.bi-question-octagon-fill:before {
  content: "";
}

.bi-question-octagon:before {
  content: "";
}

.bi-question-square-fill:before {
  content: "";
}

.bi-question-square:before {
  content: "";
}

.bi-question:before {
  content: "";
}

.bi-rainbow:before {
  content: "";
}

.bi-receipt-cutoff:before {
  content: "";
}

.bi-receipt:before {
  content: "";
}

.bi-reception-0:before {
  content: "";
}

.bi-reception-1:before {
  content: "";
}

.bi-reception-2:before {
  content: "";
}

.bi-reception-3:before {
  content: "";
}

.bi-reception-4:before {
  content: "";
}

.bi-record-btn-fill:before {
  content: "";
}

.bi-record-btn:before {
  content: "";
}

.bi-record-circle-fill:before {
  content: "";
}

.bi-record-circle:before {
  content: "";
}

.bi-record-fill:before {
  content: "";
}

.bi-record:before {
  content: "";
}

.bi-record2-fill:before {
  content: "";
}

.bi-record2:before {
  content: "";
}

.bi-reply-all-fill:before {
  content: "";
}

.bi-reply-all:before {
  content: "";
}

.bi-reply-fill:before {
  content: "";
}

.bi-reply:before {
  content: "";
}

.bi-rss-fill:before {
  content: "";
}

.bi-rss:before {
  content: "";
}

.bi-rulers:before {
  content: "";
}

.bi-save-fill:before {
  content: "";
}

.bi-save:before {
  content: "";
}

.bi-save2-fill:before {
  content: "";
}

.bi-save2:before {
  content: "";
}

.bi-scissors:before {
  content: "";
}

.bi-screwdriver:before {
  content: "";
}

.bi-search:before {
  content: "";
}

.bi-segmented-nav:before {
  content: "";
}

.bi-server:before {
  content: "";
}

.bi-share-fill:before {
  content: "";
}

.bi-share:before {
  content: "";
}

.bi-shield-check:before {
  content: "";
}

.bi-shield-exclamation:before {
  content: "";
}

.bi-shield-fill-check:before {
  content: "";
}

.bi-shield-fill-exclamation:before {
  content: "";
}

.bi-shield-fill-minus:before {
  content: "";
}

.bi-shield-fill-plus:before {
  content: "";
}

.bi-shield-fill-x:before {
  content: "";
}

.bi-shield-fill:before {
  content: "";
}

.bi-shield-lock-fill:before {
  content: "";
}

.bi-shield-lock:before {
  content: "";
}

.bi-shield-minus:before {
  content: "";
}

.bi-shield-plus:before {
  content: "";
}

.bi-shield-shaded:before {
  content: "";
}

.bi-shield-slash-fill:before {
  content: "";
}

.bi-shield-slash:before {
  content: "";
}

.bi-shield-x:before {
  content: "";
}

.bi-shield:before {
  content: "";
}

.bi-shift-fill:before {
  content: "";
}

.bi-shift:before {
  content: "";
}

.bi-shop-window:before {
  content: "";
}

.bi-shop:before {
  content: "";
}

.bi-shuffle:before {
  content: "";
}

.bi-signpost-2-fill:before {
  content: "";
}

.bi-signpost-2:before {
  content: "";
}

.bi-signpost-fill:before {
  content: "";
}

.bi-signpost-split-fill:before {
  content: "";
}

.bi-signpost-split:before {
  content: "";
}

.bi-signpost:before {
  content: "";
}

.bi-sim-fill:before {
  content: "";
}

.bi-sim:before {
  content: "";
}

.bi-skip-backward-btn-fill:before {
  content: "";
}

.bi-skip-backward-btn:before {
  content: "";
}

.bi-skip-backward-circle-fill:before {
  content: "";
}

.bi-skip-backward-circle:before {
  content: "";
}

.bi-skip-backward-fill:before {
  content: "";
}

.bi-skip-backward:before {
  content: "";
}

.bi-skip-end-btn-fill:before {
  content: "";
}

.bi-skip-end-btn:before {
  content: "";
}

.bi-skip-end-circle-fill:before {
  content: "";
}

.bi-skip-end-circle:before {
  content: "";
}

.bi-skip-end-fill:before {
  content: "";
}

.bi-skip-end:before {
  content: "";
}

.bi-skip-forward-btn-fill:before {
  content: "";
}

.bi-skip-forward-btn:before {
  content: "";
}

.bi-skip-forward-circle-fill:before {
  content: "";
}

.bi-skip-forward-circle:before {
  content: "";
}

.bi-skip-forward-fill:before {
  content: "";
}

.bi-skip-forward:before {
  content: "";
}

.bi-skip-start-btn-fill:before {
  content: "";
}

.bi-skip-start-btn:before {
  content: "";
}

.bi-skip-start-circle-fill:before {
  content: "";
}

.bi-skip-start-circle:before {
  content: "";
}

.bi-skip-start-fill:before {
  content: "";
}

.bi-skip-start:before {
  content: "";
}

.bi-slack:before {
  content: "";
}

.bi-slash-circle-fill:before {
  content: "";
}

.bi-slash-circle:before {
  content: "";
}

.bi-slash-square-fill:before {
  content: "";
}

.bi-slash-square:before {
  content: "";
}

.bi-slash:before {
  content: "";
}

.bi-sliders:before {
  content: "";
}

.bi-smartwatch:before {
  content: "";
}

.bi-snow:before {
  content: "";
}

.bi-snow2:before {
  content: "";
}

.bi-snow3:before {
  content: "";
}

.bi-sort-alpha-down-alt:before {
  content: "";
}

.bi-sort-alpha-down:before {
  content: "";
}

.bi-sort-alpha-up-alt:before {
  content: "";
}

.bi-sort-alpha-up:before {
  content: "";
}

.bi-sort-down-alt:before {
  content: "";
}

.bi-sort-down:before {
  content: "";
}

.bi-sort-numeric-down-alt:before {
  content: "";
}

.bi-sort-numeric-down:before {
  content: "";
}

.bi-sort-numeric-up-alt:before {
  content: "";
}

.bi-sort-numeric-up:before {
  content: "";
}

.bi-sort-up-alt:before {
  content: "";
}

.bi-sort-up:before {
  content: "";
}

.bi-soundwave:before {
  content: "";
}

.bi-speaker-fill:before {
  content: "";
}

.bi-speaker:before {
  content: "";
}

.bi-speedometer:before {
  content: "";
}

.bi-speedometer2:before {
  content: "";
}

.bi-spellcheck:before {
  content: "";
}

.bi-square-fill:before {
  content: "";
}

.bi-square-half:before {
  content: "";
}

.bi-square:before {
  content: "";
}

.bi-stack:before {
  content: "";
}

.bi-star-fill:before {
  content: "";
}

.bi-star-half:before {
  content: "";
}

.bi-star:before {
  content: "";
}

.bi-stars:before {
  content: "";
}

.bi-stickies-fill:before {
  content: "";
}

.bi-stickies:before {
  content: "";
}

.bi-sticky-fill:before {
  content: "";
}

.bi-sticky:before {
  content: "";
}

.bi-stop-btn-fill:before {
  content: "";
}

.bi-stop-btn:before {
  content: "";
}

.bi-stop-circle-fill:before {
  content: "";
}

.bi-stop-circle:before {
  content: "";
}

.bi-stop-fill:before {
  content: "";
}

.bi-stop:before {
  content: "";
}

.bi-stoplights-fill:before {
  content: "";
}

.bi-stoplights:before {
  content: "";
}

.bi-stopwatch-fill:before {
  content: "";
}

.bi-stopwatch:before {
  content: "";
}

.bi-subtract:before {
  content: "";
}

.bi-suit-club-fill:before {
  content: "";
}

.bi-suit-club:before {
  content: "";
}

.bi-suit-diamond-fill:before {
  content: "";
}

.bi-suit-diamond:before {
  content: "";
}

.bi-suit-heart-fill:before {
  content: "";
}

.bi-suit-heart:before {
  content: "";
}

.bi-suit-spade-fill:before {
  content: "";
}

.bi-suit-spade:before {
  content: "";
}

.bi-sun-fill:before {
  content: "";
}

.bi-sun:before {
  content: "";
}

.bi-sunglasses:before {
  content: "";
}

.bi-sunrise-fill:before {
  content: "";
}

.bi-sunrise:before {
  content: "";
}

.bi-sunset-fill:before {
  content: "";
}

.bi-sunset:before {
  content: "";
}

.bi-symmetry-horizontal:before {
  content: "";
}

.bi-symmetry-vertical:before {
  content: "";
}

.bi-table:before {
  content: "";
}

.bi-tablet-fill:before {
  content: "";
}

.bi-tablet-landscape-fill:before {
  content: "";
}

.bi-tablet-landscape:before {
  content: "";
}

.bi-tablet:before {
  content: "";
}

.bi-tag-fill:before {
  content: "";
}

.bi-tag:before {
  content: "";
}

.bi-tags-fill:before {
  content: "";
}

.bi-tags:before {
  content: "";
}

.bi-telegram:before {
  content: "";
}

.bi-telephone-fill:before {
  content: "";
}

.bi-telephone-forward-fill:before {
  content: "";
}

.bi-telephone-forward:before {
  content: "";
}

.bi-telephone-inbound-fill:before {
  content: "";
}

.bi-telephone-inbound:before {
  content: "";
}

.bi-telephone-minus-fill:before {
  content: "";
}

.bi-telephone-minus:before {
  content: "";
}

.bi-telephone-outbound-fill:before {
  content: "";
}

.bi-telephone-outbound:before {
  content: "";
}

.bi-telephone-plus-fill:before {
  content: "";
}

.bi-telephone-plus:before {
  content: "";
}

.bi-telephone-x-fill:before {
  content: "";
}

.bi-telephone-x:before {
  content: "";
}

.bi-telephone:before {
  content: "";
}

.bi-terminal-fill:before {
  content: "";
}

.bi-terminal:before {
  content: "";
}

.bi-text-center:before {
  content: "";
}

.bi-text-indent-left:before {
  content: "";
}

.bi-text-indent-right:before {
  content: "";
}

.bi-text-left:before {
  content: "";
}

.bi-text-paragraph:before {
  content: "";
}

.bi-text-right:before {
  content: "";
}

.bi-textarea-resize:before {
  content: "";
}

.bi-textarea-t:before {
  content: "";
}

.bi-textarea:before {
  content: "";
}

.bi-thermometer-half:before {
  content: "";
}

.bi-thermometer-high:before {
  content: "";
}

.bi-thermometer-low:before {
  content: "";
}

.bi-thermometer-snow:before {
  content: "";
}

.bi-thermometer-sun:before {
  content: "";
}

.bi-thermometer:before {
  content: "";
}

.bi-three-dots-vertical:before {
  content: "";
}

.bi-three-dots:before {
  content: "";
}

.bi-toggle-off:before {
  content: "";
}

.bi-toggle-on:before {
  content: "";
}

.bi-toggle2-off:before {
  content: "";
}

.bi-toggle2-on:before {
  content: "";
}

.bi-toggles:before {
  content: "";
}

.bi-toggles2:before {
  content: "";
}

.bi-tools:before {
  content: "";
}

.bi-tornado:before {
  content: "";
}

.bi-trash-fill:before {
  content: "";
}

.bi-trash:before {
  content: "";
}

.bi-trash2-fill:before {
  content: "";
}

.bi-trash2:before {
  content: "";
}

.bi-tree-fill:before {
  content: "";
}

.bi-tree:before {
  content: "";
}

.bi-triangle-fill:before {
  content: "";
}

.bi-triangle-half:before {
  content: "";
}

.bi-triangle:before {
  content: "";
}

.bi-trophy-fill:before {
  content: "";
}

.bi-trophy:before {
  content: "";
}

.bi-tropical-storm:before {
  content: "";
}

.bi-truck-flatbed:before {
  content: "";
}

.bi-truck:before {
  content: "";
}

.bi-tsunami:before {
  content: "";
}

.bi-tv-fill:before {
  content: "";
}

.bi-tv:before {
  content: "";
}

.bi-twitch:before {
  content: "";
}

.bi-twitter:before {
  content: "";
}

.bi-type-bold:before {
  content: "";
}

.bi-type-h1:before {
  content: "";
}

.bi-type-h2:before {
  content: "";
}

.bi-type-h3:before {
  content: "";
}

.bi-type-italic:before {
  content: "";
}

.bi-type-strikethrough:before {
  content: "";
}

.bi-type-underline:before {
  content: "";
}

.bi-type:before {
  content: "";
}

.bi-ui-checks-grid:before {
  content: "";
}

.bi-ui-checks:before {
  content: "";
}

.bi-ui-radios-grid:before {
  content: "";
}

.bi-ui-radios:before {
  content: "";
}

.bi-umbrella-fill:before {
  content: "";
}

.bi-umbrella:before {
  content: "";
}

.bi-union:before {
  content: "";
}

.bi-unlock-fill:before {
  content: "";
}

.bi-unlock:before {
  content: "";
}

.bi-upc-scan:before {
  content: "";
}

.bi-upc:before {
  content: "";
}

.bi-upload:before {
  content: "";
}

.bi-vector-pen:before {
  content: "";
}

.bi-view-list:before {
  content: "";
}

.bi-view-stacked:before {
  content: "";
}

.bi-vinyl-fill:before {
  content: "";
}

.bi-vinyl:before {
  content: "";
}

.bi-voicemail:before {
  content: "";
}

.bi-volume-down-fill:before {
  content: "";
}

.bi-volume-down:before {
  content: "";
}

.bi-volume-mute-fill:before {
  content: "";
}

.bi-volume-mute:before {
  content: "";
}

.bi-volume-off-fill:before {
  content: "";
}

.bi-volume-off:before {
  content: "";
}

.bi-volume-up-fill:before {
  content: "";
}

.bi-volume-up:before {
  content: "";
}

.bi-vr:before {
  content: "";
}

.bi-wallet-fill:before {
  content: "";
}

.bi-wallet:before {
  content: "";
}

.bi-wallet2:before {
  content: "";
}

.bi-watch:before {
  content: "";
}

.bi-water:before {
  content: "";
}

.bi-whatsapp:before {
  content: "";
}

.bi-wifi-1:before {
  content: "";
}

.bi-wifi-2:before {
  content: "";
}

.bi-wifi-off:before {
  content: "";
}

.bi-wifi:before {
  content: "";
}

.bi-wind:before {
  content: "";
}

.bi-window-dock:before {
  content: "";
}

.bi-window-sidebar:before {
  content: "";
}

.bi-window:before {
  content: "";
}

.bi-wrench:before {
  content: "";
}

.bi-x-circle-fill:before {
  content: "";
}

.bi-x-circle:before {
  content: "";
}

.bi-x-diamond-fill:before {
  content: "";
}

.bi-x-diamond:before {
  content: "";
}

.bi-x-octagon-fill:before {
  content: "";
}

.bi-x-octagon:before {
  content: "";
}

.bi-x-square-fill:before {
  content: "";
}

.bi-x-square:before {
  content: "";
}

.bi-x:before {
  content: "";
}

.bi-youtube:before {
  content: "";
}

.bi-zoom-in:before {
  content: "";
}

.bi-zoom-out:before {
  content: "";
}

.bi-bank:before {
  content: "";
}

.bi-bank2:before {
  content: "";
}

.bi-bell-slash-fill:before {
  content: "";
}

.bi-bell-slash:before {
  content: "";
}

.bi-cash-coin:before {
  content: "";
}

.bi-check-lg:before {
  content: "";
}

.bi-coin:before {
  content: "";
}

.bi-currency-bitcoin:before {
  content: "";
}

.bi-currency-dollar:before {
  content: "";
}

.bi-currency-euro:before {
  content: "";
}

.bi-currency-exchange:before {
  content: "";
}

.bi-currency-pound:before {
  content: "";
}

.bi-currency-yen:before {
  content: "";
}

.bi-dash-lg:before {
  content: "";
}

.bi-exclamation-lg:before {
  content: "";
}

.bi-file-earmark-pdf-fill:before {
  content: "";
}

.bi-file-earmark-pdf:before {
  content: "";
}

.bi-file-pdf-fill:before {
  content: "";
}

.bi-file-pdf:before {
  content: "";
}

.bi-gender-ambiguous:before {
  content: "";
}

.bi-gender-female:before {
  content: "";
}

.bi-gender-male:before {
  content: "";
}

.bi-gender-trans:before {
  content: "";
}

.bi-headset-vr:before {
  content: "";
}

.bi-info-lg:before {
  content: "";
}

.bi-mastodon:before {
  content: "";
}

.bi-messenger:before {
  content: "";
}

.bi-piggy-bank-fill:before {
  content: "";
}

.bi-piggy-bank:before {
  content: "";
}

.bi-pin-map-fill:before {
  content: "";
}

.bi-pin-map:before {
  content: "";
}

.bi-plus-lg:before {
  content: "";
}

.bi-question-lg:before {
  content: "";
}

.bi-recycle:before {
  content: "";
}

.bi-reddit:before {
  content: "";
}

.bi-safe-fill:before {
  content: "";
}

.bi-safe2-fill:before {
  content: "";
}

.bi-safe2:before {
  content: "";
}

.bi-sd-card-fill:before {
  content: "";
}

.bi-sd-card:before {
  content: "";
}

.bi-skype:before {
  content: "";
}

.bi-slash-lg:before {
  content: "";
}

.bi-translate:before {
  content: "";
}

.bi-x-lg:before {
  content: "";
}

.bi-safe:before {
  content: "";
}

.bi-apple:before {
  content: "";
}

.bi-microsoft:before {
  content: "";
}

.bi-windows:before {
  content: "";
}

.bi-behance:before {
  content: "";
}

.bi-dribbble:before {
  content: "";
}

.bi-line:before {
  content: "";
}

.bi-medium:before {
  content: "";
}

.bi-paypal:before {
  content: "";
}

.bi-pinterest:before {
  content: "";
}

.bi-signal:before {
  content: "";
}

.bi-snapchat:before {
  content: "";
}

.bi-spotify:before {
  content: "";
}

.bi-stack-overflow:before {
  content: "";
}

.bi-strava:before {
  content: "";
}

.bi-wordpress:before {
  content: "";
}

.bi-vimeo:before {
  content: "";
}

.bi-activity:before {
  content: "";
}

.bi-easel2-fill:before {
  content: "";
}

.bi-easel2:before {
  content: "";
}

.bi-easel3-fill:before {
  content: "";
}

.bi-easel3:before {
  content: "";
}

.bi-fan:before {
  content: "";
}

.bi-fingerprint:before {
  content: "";
}

.bi-graph-down-arrow:before {
  content: "";
}

.bi-graph-up-arrow:before {
  content: "";
}

.bi-hypnotize:before {
  content: "";
}

.bi-magic:before {
  content: "";
}

.bi-person-rolodex:before {
  content: "";
}

.bi-person-video:before {
  content: "";
}

.bi-person-video2:before {
  content: "";
}

.bi-person-video3:before {
  content: "";
}

.bi-person-workspace:before {
  content: "";
}

.bi-radioactive:before {
  content: "";
}

.bi-webcam-fill:before {
  content: "";
}

.bi-webcam:before {
  content: "";
}

.bi-yin-yang:before {
  content: "";
}

.bi-bandaid-fill:before {
  content: "";
}

.bi-bandaid:before {
  content: "";
}

.bi-bluetooth:before {
  content: "";
}

.bi-body-text:before {
  content: "";
}

.bi-boombox:before {
  content: "";
}

.bi-boxes:before {
  content: "";
}

.bi-dpad-fill:before {
  content: "";
}

.bi-dpad:before {
  content: "";
}

.bi-ear-fill:before {
  content: "";
}

.bi-ear:before {
  content: "";
}

.bi-envelope-check-fill:before {
  content: "";
}

.bi-envelope-check:before {
  content: "";
}

.bi-envelope-dash-fill:before {
  content: "";
}

.bi-envelope-dash:before {
  content: "";
}

.bi-envelope-exclamation-fill:before {
  content: "";
}

.bi-envelope-exclamation:before {
  content: "";
}

.bi-envelope-plus-fill:before {
  content: "";
}

.bi-envelope-plus:before {
  content: "";
}

.bi-envelope-slash-fill:before {
  content: "";
}

.bi-envelope-slash:before {
  content: "";
}

.bi-envelope-x-fill:before {
  content: "";
}

.bi-envelope-x:before {
  content: "";
}

.bi-explicit-fill:before {
  content: "";
}

.bi-explicit:before {
  content: "";
}

.bi-git:before {
  content: "";
}

.bi-infinity:before {
  content: "";
}

.bi-list-columns-reverse:before {
  content: "";
}

.bi-list-columns:before {
  content: "";
}

.bi-meta:before {
  content: "";
}

.bi-nintendo-switch:before {
  content: "";
}

.bi-pc-display-horizontal:before {
  content: "";
}

.bi-pc-display:before {
  content: "";
}

.bi-pc-horizontal:before {
  content: "";
}

.bi-pc:before {
  content: "";
}

.bi-playstation:before {
  content: "";
}

.bi-plus-slash-minus:before {
  content: "";
}

.bi-projector-fill:before {
  content: "";
}

.bi-projector:before {
  content: "";
}

.bi-qr-code-scan:before {
  content: "";
}

.bi-qr-code:before {
  content: "";
}

.bi-quora:before {
  content: "";
}

.bi-quote:before {
  content: "";
}

.bi-robot:before {
  content: "";
}

.bi-send-check-fill:before {
  content: "";
}

.bi-send-check:before {
  content: "";
}

.bi-send-dash-fill:before {
  content: "";
}

.bi-send-dash:before {
  content: "";
}

.bi-send-exclamation-fill:before {
  content: "";
}

.bi-send-exclamation:before {
  content: "";
}

.bi-send-fill:before {
  content: "";
}

.bi-send-plus-fill:before {
  content: "";
}

.bi-send-plus:before {
  content: "";
}

.bi-send-slash-fill:before {
  content: "";
}

.bi-send-slash:before {
  content: "";
}

.bi-send-x-fill:before {
  content: "";
}

.bi-send-x:before {
  content: "";
}

.bi-send:before {
  content: "";
}

.bi-steam:before {
  content: "";
}

.bi-terminal-dash:before {
  content: "";
}

.bi-terminal-plus:before {
  content: "";
}

.bi-terminal-split:before {
  content: "";
}

.bi-ticket-detailed-fill:before {
  content: "";
}

.bi-ticket-detailed:before {
  content: "";
}

.bi-ticket-fill:before {
  content: "";
}

.bi-ticket-perforated-fill:before {
  content: "";
}

.bi-ticket-perforated:before {
  content: "";
}

.bi-ticket:before {
  content: "";
}

.bi-tiktok:before {
  content: "";
}

.bi-window-dash:before {
  content: "";
}

.bi-window-desktop:before {
  content: "";
}

.bi-window-fullscreen:before {
  content: "";
}

.bi-window-plus:before {
  content: "";
}

.bi-window-split:before {
  content: "";
}

.bi-window-stack:before {
  content: "";
}

.bi-window-x:before {
  content: "";
}

.bi-xbox:before {
  content: "";
}

.bi-ethernet:before {
  content: "";
}

.bi-hdmi-fill:before {
  content: "";
}

.bi-hdmi:before {
  content: "";
}

.bi-usb-c-fill:before {
  content: "";
}

.bi-usb-c:before {
  content: "";
}

.bi-usb-fill:before {
  content: "";
}

.bi-usb-plug-fill:before {
  content: "";
}

.bi-usb-plug:before {
  content: "";
}

.bi-usb-symbol:before {
  content: "";
}

.bi-usb:before {
  content: "";
}

.bi-boombox-fill:before {
  content: "";
}

.bi-displayport:before {
  content: "";
}

.bi-gpu-card:before {
  content: "";
}

.bi-memory:before {
  content: "";
}

.bi-modem-fill:before {
  content: "";
}

.bi-modem:before {
  content: "";
}

.bi-motherboard-fill:before {
  content: "";
}

.bi-motherboard:before {
  content: "";
}

.bi-optical-audio-fill:before {
  content: "";
}

.bi-optical-audio:before {
  content: "";
}

.bi-pci-card:before {
  content: "";
}

.bi-router-fill:before {
  content: "";
}

.bi-router:before {
  content: "";
}

.bi-thunderbolt-fill:before {
  content: "";
}

.bi-thunderbolt:before {
  content: "";
}

.bi-usb-drive-fill:before {
  content: "";
}

.bi-usb-drive:before {
  content: "";
}

.bi-usb-micro-fill:before {
  content: "";
}

.bi-usb-micro:before {
  content: "";
}

.bi-usb-mini-fill:before {
  content: "";
}

.bi-usb-mini:before {
  content: "";
}

.bi-cloud-haze2:before {
  content: "";
}

.bi-device-hdd-fill:before {
  content: "";
}

.bi-device-hdd:before {
  content: "";
}

.bi-device-ssd-fill:before {
  content: "";
}

.bi-device-ssd:before {
  content: "";
}

.bi-displayport-fill:before {
  content: "";
}

.bi-mortarboard-fill:before {
  content: "";
}

.bi-mortarboard:before {
  content: "";
}

.bi-terminal-x:before {
  content: "";
}

.bi-arrow-through-heart-fill:before {
  content: "";
}

.bi-arrow-through-heart:before {
  content: "";
}

.bi-badge-sd-fill:before {
  content: "";
}

.bi-badge-sd:before {
  content: "";
}

.bi-bag-heart-fill:before {
  content: "";
}

.bi-bag-heart:before {
  content: "";
}

.bi-balloon-fill:before {
  content: "";
}

.bi-balloon-heart-fill:before {
  content: "";
}

.bi-balloon-heart:before {
  content: "";
}

.bi-balloon:before {
  content: "";
}

.bi-box2-fill:before {
  content: "";
}

.bi-box2-heart-fill:before {
  content: "";
}

.bi-box2-heart:before {
  content: "";
}

.bi-box2:before {
  content: "";
}

.bi-braces-asterisk:before {
  content: "";
}

.bi-calendar-heart-fill:before {
  content: "";
}

.bi-calendar-heart:before {
  content: "";
}

.bi-calendar2-heart-fill:before {
  content: "";
}

.bi-calendar2-heart:before {
  content: "";
}

.bi-chat-heart-fill:before {
  content: "";
}

.bi-chat-heart:before {
  content: "";
}

.bi-chat-left-heart-fill:before {
  content: "";
}

.bi-chat-left-heart:before {
  content: "";
}

.bi-chat-right-heart-fill:before {
  content: "";
}

.bi-chat-right-heart:before {
  content: "";
}

.bi-chat-square-heart-fill:before {
  content: "";
}

.bi-chat-square-heart:before {
  content: "";
}

.bi-clipboard-check-fill:before {
  content: "";
}

.bi-clipboard-data-fill:before {
  content: "";
}

.bi-clipboard-fill:before {
  content: "";
}

.bi-clipboard-heart-fill:before {
  content: "";
}

.bi-clipboard-heart:before {
  content: "";
}

.bi-clipboard-minus-fill:before {
  content: "";
}

.bi-clipboard-plus-fill:before {
  content: "";
}

.bi-clipboard-pulse:before {
  content: "";
}

.bi-clipboard-x-fill:before {
  content: "";
}

.bi-clipboard2-check-fill:before {
  content: "";
}

.bi-clipboard2-check:before {
  content: "";
}

.bi-clipboard2-data-fill:before {
  content: "";
}

.bi-clipboard2-data:before {
  content: "";
}

.bi-clipboard2-fill:before {
  content: "";
}

.bi-clipboard2-heart-fill:before {
  content: "";
}

.bi-clipboard2-heart:before {
  content: "";
}

.bi-clipboard2-minus-fill:before {
  content: "";
}

.bi-clipboard2-minus:before {
  content: "";
}

.bi-clipboard2-plus-fill:before {
  content: "";
}

.bi-clipboard2-plus:before {
  content: "";
}

.bi-clipboard2-pulse-fill:before {
  content: "";
}

.bi-clipboard2-pulse:before {
  content: "";
}

.bi-clipboard2-x-fill:before {
  content: "";
}

.bi-clipboard2-x:before {
  content: "";
}

.bi-clipboard2:before {
  content: "";
}

.bi-emoji-kiss-fill:before {
  content: "";
}

.bi-emoji-kiss:before {
  content: "";
}

.bi-envelope-heart-fill:before {
  content: "";
}

.bi-envelope-heart:before {
  content: "";
}

.bi-envelope-open-heart-fill:before {
  content: "";
}

.bi-envelope-open-heart:before {
  content: "";
}

.bi-envelope-paper-fill:before {
  content: "";
}

.bi-envelope-paper-heart-fill:before {
  content: "";
}

.bi-envelope-paper-heart:before {
  content: "";
}

.bi-envelope-paper:before {
  content: "";
}

.bi-filetype-aac:before {
  content: "";
}

.bi-filetype-ai:before {
  content: "";
}

.bi-filetype-bmp:before {
  content: "";
}

.bi-filetype-cs:before {
  content: "";
}

.bi-filetype-css:before {
  content: "";
}

.bi-filetype-csv:before {
  content: "";
}

.bi-filetype-doc:before {
  content: "";
}

.bi-filetype-docx:before {
  content: "";
}

.bi-filetype-exe:before {
  content: "";
}

.bi-filetype-gif:before {
  content: "";
}

.bi-filetype-heic:before {
  content: "";
}

.bi-filetype-html:before {
  content: "";
}

.bi-filetype-java:before {
  content: "";
}

.bi-filetype-jpg:before {
  content: "";
}

.bi-filetype-js:before {
  content: "";
}

.bi-filetype-jsx:before {
  content: "";
}

.bi-filetype-key:before {
  content: "";
}

.bi-filetype-m4p:before {
  content: "";
}

.bi-filetype-md:before {
  content: "";
}

.bi-filetype-mdx:before {
  content: "";
}

.bi-filetype-mov:before {
  content: "";
}

.bi-filetype-mp3:before {
  content: "";
}

.bi-filetype-mp4:before {
  content: "";
}

.bi-filetype-otf:before {
  content: "";
}

.bi-filetype-pdf:before {
  content: "";
}

.bi-filetype-php:before {
  content: "";
}

.bi-filetype-png:before {
  content: "";
}

.bi-filetype-ppt:before {
  content: "";
}

.bi-filetype-psd:before {
  content: "";
}

.bi-filetype-py:before {
  content: "";
}

.bi-filetype-raw:before {
  content: "";
}

.bi-filetype-rb:before {
  content: "";
}

.bi-filetype-sass:before {
  content: "";
}

.bi-filetype-scss:before {
  content: "";
}

.bi-filetype-sh:before {
  content: "";
}

.bi-filetype-svg:before {
  content: "";
}

.bi-filetype-tiff:before {
  content: "";
}

.bi-filetype-tsx:before {
  content: "";
}

.bi-filetype-ttf:before {
  content: "";
}

.bi-filetype-txt:before {
  content: "";
}

.bi-filetype-wav:before {
  content: "";
}

.bi-filetype-woff:before {
  content: "";
}

.bi-filetype-xls:before {
  content: "";
}

.bi-filetype-xml:before {
  content: "";
}

.bi-filetype-yml:before {
  content: "";
}

.bi-heart-arrow:before {
  content: "";
}

.bi-heart-pulse-fill:before {
  content: "";
}

.bi-heart-pulse:before {
  content: "";
}

.bi-heartbreak-fill:before {
  content: "";
}

.bi-heartbreak:before {
  content: "";
}

.bi-hearts:before {
  content: "";
}

.bi-hospital-fill:before {
  content: "";
}

.bi-hospital:before {
  content: "";
}

.bi-house-heart-fill:before {
  content: "";
}

.bi-house-heart:before {
  content: "";
}

.bi-incognito:before {
  content: "";
}

.bi-magnet-fill:before {
  content: "";
}

.bi-magnet:before {
  content: "";
}

.bi-person-heart:before {
  content: "";
}

.bi-person-hearts:before {
  content: "";
}

.bi-phone-flip:before {
  content: "";
}

.bi-plugin:before {
  content: "";
}

.bi-postage-fill:before {
  content: "";
}

.bi-postage-heart-fill:before {
  content: "";
}

.bi-postage-heart:before {
  content: "";
}

.bi-postage:before {
  content: "";
}

.bi-postcard-fill:before {
  content: "";
}

.bi-postcard-heart-fill:before {
  content: "";
}

.bi-postcard-heart:before {
  content: "";
}

.bi-postcard:before {
  content: "";
}

.bi-search-heart-fill:before {
  content: "";
}

.bi-search-heart:before {
  content: "";
}

.bi-sliders2-vertical:before {
  content: "";
}

.bi-sliders2:before {
  content: "";
}

.bi-trash3-fill:before {
  content: "";
}

.bi-trash3:before {
  content: "";
}

.bi-valentine:before {
  content: "";
}

.bi-valentine2:before {
  content: "";
}

.bi-wrench-adjustable-circle-fill:before {
  content: "";
}

.bi-wrench-adjustable-circle:before {
  content: "";
}

.bi-wrench-adjustable:before {
  content: "";
}

.bi-filetype-json:before {
  content: "";
}

.bi-filetype-pptx:before {
  content: "";
}

.bi-filetype-xlsx:before {
  content: "";
}

.bi-1-circle-fill:before {
  content: "";
}

.bi-1-circle:before {
  content: "";
}

.bi-1-square-fill:before {
  content: "";
}

.bi-1-square:before {
  content: "";
}

.bi-2-circle-fill:before {
  content: "";
}

.bi-2-circle:before {
  content: "";
}

.bi-2-square-fill:before {
  content: "";
}

.bi-2-square:before {
  content: "";
}

.bi-3-circle-fill:before {
  content: "";
}

.bi-3-circle:before {
  content: "";
}

.bi-3-square-fill:before {
  content: "";
}

.bi-3-square:before {
  content: "";
}

.bi-4-circle-fill:before {
  content: "";
}

.bi-4-circle:before {
  content: "";
}

.bi-4-square-fill:before {
  content: "";
}

.bi-4-square:before {
  content: "";
}

.bi-5-circle-fill:before {
  content: "";
}

.bi-5-circle:before {
  content: "";
}

.bi-5-square-fill:before {
  content: "";
}

.bi-5-square:before {
  content: "";
}

.bi-6-circle-fill:before {
  content: "";
}

.bi-6-circle:before {
  content: "";
}

.bi-6-square-fill:before {
  content: "";
}

.bi-6-square:before {
  content: "";
}

.bi-7-circle-fill:before {
  content: "";
}

.bi-7-circle:before {
  content: "";
}

.bi-7-square-fill:before {
  content: "";
}

.bi-7-square:before {
  content: "";
}

.bi-8-circle-fill:before {
  content: "";
}

.bi-8-circle:before {
  content: "";
}

.bi-8-square-fill:before {
  content: "";
}

.bi-8-square:before {
  content: "";
}

.bi-9-circle-fill:before {
  content: "";
}

.bi-9-circle:before {
  content: "";
}

.bi-9-square-fill:before {
  content: "";
}

.bi-9-square:before {
  content: "";
}

.bi-airplane-engines-fill:before {
  content: "";
}

.bi-airplane-engines:before {
  content: "";
}

.bi-airplane-fill:before {
  content: "";
}

.bi-airplane:before {
  content: "";
}

.bi-alexa:before {
  content: "";
}

.bi-alipay:before {
  content: "";
}

.bi-android:before {
  content: "";
}

.bi-android2:before {
  content: "";
}

.bi-box-fill:before {
  content: "";
}

.bi-box-seam-fill:before {
  content: "";
}

.bi-browser-chrome:before {
  content: "";
}

.bi-browser-edge:before {
  content: "";
}

.bi-browser-firefox:before {
  content: "";
}

.bi-browser-safari:before {
  content: "";
}

.bi-c-circle-fill:before {
  content: "";
}

.bi-c-circle:before {
  content: "";
}

.bi-c-square-fill:before {
  content: "";
}

.bi-c-square:before {
  content: "";
}

.bi-capsule-pill:before {
  content: "";
}

.bi-capsule:before {
  content: "";
}

.bi-car-front-fill:before {
  content: "";
}

.bi-car-front:before {
  content: "";
}

.bi-cassette-fill:before {
  content: "";
}

.bi-cassette:before {
  content: "";
}

.bi-cc-circle-fill:before {
  content: "";
}

.bi-cc-circle:before {
  content: "";
}

.bi-cc-square-fill:before {
  content: "";
}

.bi-cc-square:before {
  content: "";
}

.bi-cup-hot-fill:before {
  content: "";
}

.bi-cup-hot:before {
  content: "";
}

.bi-currency-rupee:before {
  content: "";
}

.bi-dropbox:before {
  content: "";
}

.bi-escape:before {
  content: "";
}

.bi-fast-forward-btn-fill:before {
  content: "";
}

.bi-fast-forward-btn:before {
  content: "";
}

.bi-fast-forward-circle-fill:before {
  content: "";
}

.bi-fast-forward-circle:before {
  content: "";
}

.bi-fast-forward-fill:before {
  content: "";
}

.bi-fast-forward:before {
  content: "";
}

.bi-filetype-sql:before {
  content: "";
}

.bi-fire:before {
  content: "";
}

.bi-google-play:before {
  content: "";
}

.bi-h-circle-fill:before {
  content: "";
}

.bi-h-circle:before {
  content: "";
}

.bi-h-square-fill:before {
  content: "";
}

.bi-h-square:before {
  content: "";
}

.bi-indent:before {
  content: "";
}

.bi-lungs-fill:before {
  content: "";
}

.bi-lungs:before {
  content: "";
}

.bi-microsoft-teams:before {
  content: "";
}

.bi-p-circle-fill:before {
  content: "";
}

.bi-p-circle:before {
  content: "";
}

.bi-p-square-fill:before {
  content: "";
}

.bi-p-square:before {
  content: "";
}

.bi-pass-fill:before {
  content: "";
}

.bi-pass:before {
  content: "";
}

.bi-prescription:before {
  content: "";
}

.bi-prescription2:before {
  content: "";
}

.bi-r-circle-fill:before {
  content: "";
}

.bi-r-circle:before {
  content: "";
}

.bi-r-square-fill:before {
  content: "";
}

.bi-r-square:before {
  content: "";
}

.bi-repeat-1:before {
  content: "";
}

.bi-repeat:before {
  content: "";
}

.bi-rewind-btn-fill:before {
  content: "";
}

.bi-rewind-btn:before {
  content: "";
}

.bi-rewind-circle-fill:before {
  content: "";
}

.bi-rewind-circle:before {
  content: "";
}

.bi-rewind-fill:before {
  content: "";
}

.bi-rewind:before {
  content: "";
}

.bi-train-freight-front-fill:before {
  content: "";
}

.bi-train-freight-front:before {
  content: "";
}

.bi-train-front-fill:before {
  content: "";
}

.bi-train-front:before {
  content: "";
}

.bi-train-lightrail-front-fill:before {
  content: "";
}

.bi-train-lightrail-front:before {
  content: "";
}

.bi-truck-front-fill:before {
  content: "";
}

.bi-truck-front:before {
  content: "";
}

.bi-ubuntu:before {
  content: "";
}

.bi-unindent:before {
  content: "";
}

.bi-unity:before {
  content: "";
}

.bi-universal-access-circle:before {
  content: "";
}

.bi-universal-access:before {
  content: "";
}

.bi-virus:before {
  content: "";
}

.bi-virus2:before {
  content: "";
}

.bi-wechat:before {
  content: "";
}

.bi-yelp:before {
  content: "";
}

.bi-sign-stop-fill:before {
  content: "";
}

.bi-sign-stop-lights-fill:before {
  content: "";
}

.bi-sign-stop-lights:before {
  content: "";
}

.bi-sign-stop:before {
  content: "";
}

.bi-sign-turn-left-fill:before {
  content: "";
}

.bi-sign-turn-left:before {
  content: "";
}

.bi-sign-turn-right-fill:before {
  content: "";
}

.bi-sign-turn-right:before {
  content: "";
}

.bi-sign-turn-slight-left-fill:before {
  content: "";
}

.bi-sign-turn-slight-left:before {
  content: "";
}

.bi-sign-turn-slight-right-fill:before {
  content: "";
}

.bi-sign-turn-slight-right:before {
  content: "";
}

.bi-sign-yield-fill:before {
  content: "";
}

.bi-sign-yield:before {
  content: "";
}

.bi-ev-station-fill:before {
  content: "";
}

.bi-ev-station:before {
  content: "";
}

.bi-fuel-pump-diesel-fill:before {
  content: "";
}

.bi-fuel-pump-diesel:before {
  content: "";
}

.bi-fuel-pump-fill:before {
  content: "";
}

.bi-fuel-pump:before {
  content: "";
}

.bi-0-circle-fill:before {
  content: "";
}

.bi-0-circle:before {
  content: "";
}

.bi-0-square-fill:before {
  content: "";
}

.bi-0-square:before {
  content: "";
}

.bi-rocket-fill:before {
  content: "";
}

.bi-rocket-takeoff-fill:before {
  content: "";
}

.bi-rocket-takeoff:before {
  content: "";
}

.bi-rocket:before {
  content: "";
}

.bi-stripe:before {
  content: "";
}

.bi-subscript:before {
  content: "";
}

.bi-superscript:before {
  content: "";
}

.bi-trello:before {
  content: "";
}

.bi-envelope-at-fill:before {
  content: "";
}

.bi-envelope-at:before {
  content: "";
}

.bi-regex:before {
  content: "";
}

.bi-text-wrap:before {
  content: "";
}

.bi-sign-dead-end-fill:before {
  content: "";
}

.bi-sign-dead-end:before {
  content: "";
}

.bi-sign-do-not-enter-fill:before {
  content: "";
}

.bi-sign-do-not-enter:before {
  content: "";
}

.bi-sign-intersection-fill:before {
  content: "";
}

.bi-sign-intersection-side-fill:before {
  content: "";
}

.bi-sign-intersection-side:before {
  content: "";
}

.bi-sign-intersection-t-fill:before {
  content: "";
}

.bi-sign-intersection-t:before {
  content: "";
}

.bi-sign-intersection-y-fill:before {
  content: "";
}

.bi-sign-intersection-y:before {
  content: "";
}

.bi-sign-intersection:before {
  content: "";
}

.bi-sign-merge-left-fill:before {
  content: "";
}

.bi-sign-merge-left:before {
  content: "";
}

.bi-sign-merge-right-fill:before {
  content: "";
}

.bi-sign-merge-right:before {
  content: "";
}

.bi-sign-no-left-turn-fill:before {
  content: "";
}

.bi-sign-no-left-turn:before {
  content: "";
}

.bi-sign-no-parking-fill:before {
  content: "";
}

.bi-sign-no-parking:before {
  content: "";
}

.bi-sign-no-right-turn-fill:before {
  content: "";
}

.bi-sign-no-right-turn:before {
  content: "";
}

.bi-sign-railroad-fill:before {
  content: "";
}

.bi-sign-railroad:before {
  content: "";
}

.bi-building-add:before {
  content: "";
}

.bi-building-check:before {
  content: "";
}

.bi-building-dash:before {
  content: "";
}

.bi-building-down:before {
  content: "";
}

.bi-building-exclamation:before {
  content: "";
}

.bi-building-fill-add:before {
  content: "";
}

.bi-building-fill-check:before {
  content: "";
}

.bi-building-fill-dash:before {
  content: "";
}

.bi-building-fill-down:before {
  content: "";
}

.bi-building-fill-exclamation:before {
  content: "";
}

.bi-building-fill-gear:before {
  content: "";
}

.bi-building-fill-lock:before {
  content: "";
}

.bi-building-fill-slash:before {
  content: "";
}

.bi-building-fill-up:before {
  content: "";
}

.bi-building-fill-x:before {
  content: "";
}

.bi-building-fill:before {
  content: "";
}

.bi-building-gear:before {
  content: "";
}

.bi-building-lock:before {
  content: "";
}

.bi-building-slash:before {
  content: "";
}

.bi-building-up:before {
  content: "";
}

.bi-building-x:before {
  content: "";
}

.bi-buildings-fill:before {
  content: "";
}

.bi-buildings:before {
  content: "";
}

.bi-bus-front-fill:before {
  content: "";
}

.bi-bus-front:before {
  content: "";
}

.bi-ev-front-fill:before {
  content: "";
}

.bi-ev-front:before {
  content: "";
}

.bi-globe-americas:before {
  content: "";
}

.bi-globe-asia-australia:before {
  content: "";
}

.bi-globe-central-south-asia:before {
  content: "";
}

.bi-globe-europe-africa:before {
  content: "";
}

.bi-house-add-fill:before {
  content: "";
}

.bi-house-add:before {
  content: "";
}

.bi-house-check-fill:before {
  content: "";
}

.bi-house-check:before {
  content: "";
}

.bi-house-dash-fill:before {
  content: "";
}

.bi-house-dash:before {
  content: "";
}

.bi-house-down-fill:before {
  content: "";
}

.bi-house-down:before {
  content: "";
}

.bi-house-exclamation-fill:before {
  content: "";
}

.bi-house-exclamation:before {
  content: "";
}

.bi-house-gear-fill:before {
  content: "";
}

.bi-house-gear:before {
  content: "";
}

.bi-house-lock-fill:before {
  content: "";
}

.bi-house-lock:before {
  content: "";
}

.bi-house-slash-fill:before {
  content: "";
}

.bi-house-slash:before {
  content: "";
}

.bi-house-up-fill:before {
  content: "";
}

.bi-house-up:before {
  content: "";
}

.bi-house-x-fill:before {
  content: "";
}

.bi-house-x:before {
  content: "";
}

.bi-person-add:before {
  content: "";
}

.bi-person-down:before {
  content: "";
}

.bi-person-exclamation:before {
  content: "";
}

.bi-person-fill-add:before {
  content: "";
}

.bi-person-fill-check:before {
  content: "";
}

.bi-person-fill-dash:before {
  content: "";
}

.bi-person-fill-down:before {
  content: "";
}

.bi-person-fill-exclamation:before {
  content: "";
}

.bi-person-fill-gear:before {
  content: "";
}

.bi-person-fill-lock:before {
  content: "";
}

.bi-person-fill-slash:before {
  content: "";
}

.bi-person-fill-up:before {
  content: "";
}

.bi-person-fill-x:before {
  content: "";
}

.bi-person-gear:before {
  content: "";
}

.bi-person-lock:before {
  content: "";
}

.bi-person-slash:before {
  content: "";
}

.bi-person-up:before {
  content: "";
}

.bi-scooter:before {
  content: "";
}

.bi-taxi-front-fill:before {
  content: "";
}

.bi-taxi-front:before {
  content: "";
}

.bi-amd:before {
  content: "";
}

.bi-database-add:before {
  content: "";
}

.bi-database-check:before {
  content: "";
}

.bi-database-dash:before {
  content: "";
}

.bi-database-down:before {
  content: "";
}

.bi-database-exclamation:before {
  content: "";
}

.bi-database-fill-add:before {
  content: "";
}

.bi-database-fill-check:before {
  content: "";
}

.bi-database-fill-dash:before {
  content: "";
}

.bi-database-fill-down:before {
  content: "";
}

.bi-database-fill-exclamation:before {
  content: "";
}

.bi-database-fill-gear:before {
  content: "";
}

.bi-database-fill-lock:before {
  content: "";
}

.bi-database-fill-slash:before {
  content: "";
}

.bi-database-fill-up:before {
  content: "";
}

.bi-database-fill-x:before {
  content: "";
}

.bi-database-fill:before {
  content: "";
}

.bi-database-gear:before {
  content: "";
}

.bi-database-lock:before {
  content: "";
}

.bi-database-slash:before {
  content: "";
}

.bi-database-up:before {
  content: "";
}

.bi-database-x:before {
  content: "";
}

.bi-database:before {
  content: "";
}

.bi-houses-fill:before {
  content: "";
}

.bi-houses:before {
  content: "";
}

.bi-nvidia:before {
  content: "";
}

.bi-person-vcard-fill:before {
  content: "";
}

.bi-person-vcard:before {
  content: "";
}

.bi-sina-weibo:before {
  content: "";
}

.bi-tencent-qq:before {
  content: "";
}

.bi-wikipedia:before {
  content: "";
}

.bi-alphabet-uppercase:before {
  content: "";
}

.bi-alphabet:before {
  content: "";
}

.bi-amazon:before {
  content: "";
}

.bi-arrows-collapse-vertical:before {
  content: "";
}

.bi-arrows-expand-vertical:before {
  content: "";
}

.bi-arrows-vertical:before {
  content: "";
}

.bi-arrows:before {
  content: "";
}

.bi-ban-fill:before {
  content: "";
}

.bi-ban:before {
  content: "";
}

.bi-bing:before {
  content: "";
}

.bi-cake:before {
  content: "";
}

.bi-cake2:before {
  content: "";
}

.bi-cookie:before {
  content: "";
}

.bi-copy:before {
  content: "";
}

.bi-crosshair:before {
  content: "";
}

.bi-crosshair2:before {
  content: "";
}

.bi-emoji-astonished-fill:before {
  content: "";
}

.bi-emoji-astonished:before {
  content: "";
}

.bi-emoji-grimace-fill:before {
  content: "";
}

.bi-emoji-grimace:before {
  content: "";
}

.bi-emoji-grin-fill:before {
  content: "";
}

.bi-emoji-grin:before {
  content: "";
}

.bi-emoji-surprise-fill:before {
  content: "";
}

.bi-emoji-surprise:before {
  content: "";
}

.bi-emoji-tear-fill:before {
  content: "";
}

.bi-emoji-tear:before {
  content: "";
}

.bi-envelope-arrow-down-fill:before {
  content: "";
}

.bi-envelope-arrow-down:before {
  content: "";
}

.bi-envelope-arrow-up-fill:before {
  content: "";
}

.bi-envelope-arrow-up:before {
  content: "";
}

.bi-feather:before {
  content: "";
}

.bi-feather2:before {
  content: "";
}

.bi-floppy-fill:before {
  content: "";
}

.bi-floppy:before {
  content: "";
}

.bi-floppy2-fill:before {
  content: "";
}

.bi-floppy2:before {
  content: "";
}

.bi-gitlab:before {
  content: "";
}

.bi-highlighter:before {
  content: "";
}

.bi-marker-tip:before {
  content: "";
}

.bi-nvme-fill:before {
  content: "";
}

.bi-nvme:before {
  content: "";
}

.bi-opencollective:before {
  content: "";
}

.bi-pci-card-network:before {
  content: "";
}

.bi-pci-card-sound:before {
  content: "";
}

.bi-radar:before {
  content: "";
}

.bi-send-arrow-down-fill:before {
  content: "";
}

.bi-send-arrow-down:before {
  content: "";
}

.bi-send-arrow-up-fill:before {
  content: "";
}

.bi-send-arrow-up:before {
  content: "";
}

.bi-sim-slash-fill:before {
  content: "";
}

.bi-sim-slash:before {
  content: "";
}

.bi-sourceforge:before {
  content: "";
}

.bi-substack:before {
  content: "";
}

.bi-threads-fill:before {
  content: "";
}

.bi-threads:before {
  content: "";
}

.bi-transparency:before {
  content: "";
}

.bi-twitter-x:before {
  content: "";
}

.bi-type-h4:before {
  content: "";
}

.bi-type-h5:before {
  content: "";
}

.bi-type-h6:before {
  content: "";
}

.bi-backpack-fill:before {
  content: "";
}

.bi-backpack:before {
  content: "";
}

.bi-backpack2-fill:before {
  content: "";
}

.bi-backpack2:before {
  content: "";
}

.bi-backpack3-fill:before {
  content: "";
}

.bi-backpack3:before {
  content: "";
}

.bi-backpack4-fill:before {
  content: "";
}

.bi-backpack4:before {
  content: "";
}

.bi-brilliance:before {
  content: "";
}

.bi-cake-fill:before {
  content: "";
}

.bi-cake2-fill:before {
  content: "";
}

.bi-duffle-fill:before {
  content: "";
}

.bi-duffle:before {
  content: "";
}

.bi-exposure:before {
  content: "";
}

.bi-gender-neuter:before {
  content: "";
}

.bi-highlights:before {
  content: "";
}

.bi-luggage-fill:before {
  content: "";
}

.bi-luggage:before {
  content: "";
}

.bi-mailbox-flag:before {
  content: "";
}

.bi-mailbox2-flag:before {
  content: "";
}

.bi-noise-reduction:before {
  content: "";
}

.bi-passport-fill:before {
  content: "";
}

.bi-passport:before {
  content: "";
}

.bi-person-arms-up:before {
  content: "";
}

.bi-person-raised-hand:before {
  content: "";
}

.bi-person-standing-dress:before {
  content: "";
}

.bi-person-standing:before {
  content: "";
}

.bi-person-walking:before {
  content: "";
}

.bi-person-wheelchair:before {
  content: "";
}

.bi-shadows:before {
  content: "";
}

.bi-suitcase-fill:before {
  content: "";
}

.bi-suitcase-lg-fill:before {
  content: "";
}

.bi-suitcase-lg:before {
  content: "";
}

.bi-suitcase:before {
  content: "豈";
}

.bi-suitcase2-fill:before {
  content: "更";
}

.bi-suitcase2:before {
  content: "車";
}

.bi-vignette:before {
  content: "賈";
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.button-main {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 1px;
  border-radius: .5rem;
  padding: .75rem;
  font-weight: 700;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-main:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.button-main:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.content-div {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
}

.font-heebo {
  font-family: Heebo, sans-serif;
}

.font-fira {
  font-family: Fira Mono, monospace;
}

.table-code {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  border-radius: .375rem;
  padding: .25rem;
  font-family: Fira Mono, monospace;
}

.sub-heading {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.md-grid-rows {
  grid-template-rows: auto 18em;
}

.bulb {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
}

.bulb[on="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(254 252 232 / var(--tw-border-opacity));
}

.switch {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
}

.switch:hover {
  cursor: pointer;
}

.switch[on="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

a.in-para {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:hover {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

#render-canvas {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#render-canvas[running="false"] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

#render-canvas[running="true"] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.good-error {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.bad-error {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.datalist-default {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.datalist-default:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.datalist-default[valid-reg="true"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.datalist-default[valid-reg="false"] {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.example-container {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  border-bottom-width: 1px;
  margin-top: .25rem;
  padding: .5rem;
}

.example-container h3 {
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.code-name {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.code-name[data-selected="true"] {
  font-weight: 700;
}

.code-name > * {
  display: inline-block;
}

.program-definition {
  padding: .5rem;
  font-family: Fira Mono, monospace;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.float-right {
  float: right;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.h-1\/2 {
  height: 50%;
}

.h-16 {
  height: 4rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.w-full {
  width: 100%;
}

.table-fixed {
  table-layout: fixed;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-2 {
  --tw-border-spacing-x: .5rem;
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.resize-none {
  resize: none;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

/*# sourceMappingURL=index.2e3fa6f4.css.map */
